/* eslint-disable react-hooks/exhaustive-deps */
import {FormikProvider, useField, useFormik, useFormikContext} from 'formik'
import {isEmpty, isNil} from 'lodash'
import {FC, InputHTMLAttributes, MouseEvent, useEffect, useState} from 'react'
import {OverlayTrigger, Popover} from 'react-bootstrap'
import {PopperPlacement} from '../../../../../_biha/assets/ts/components'

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  name: string
  type?: string
  label?: string
  placeholder?: string
  popperPlacement?: PopperPlacement
  buttonClassName?: string
  onClose?: () => void
  onConfirm?: () => void
}
const TextFilter: FC<Props> = ({
  name,
  type,
  label,
  placeholder,
  popperPlacement,
  buttonClassName,
  onConfirm,
  ...props
}) => {
  const outerForm = useFormikContext()
  const [field, , helpers] = useField(name)
  const {value: outerFormValue} = field
  const {setValue: setOuterFormValue} = helpers
  const [isShowingPopover, setToggleShowingPopover] = useState<boolean>(false)
  const innerForm = useFormik({
    initialValues: {search: ''},
    onSubmit: (values) => {
      setOuterFormValue(values.search)
      outerForm.submitForm()
      onConfirm?.()
    },
  })
  const searchValueWatch = innerForm.values.search
  const hasSearchValue = !isEmpty(outerFormValue) && !isNil(outerFormValue)
  const searchedLabel = hasSearchValue ? outerFormValue : label ?? name

  const handleClose = () => {
    setToggleShowingPopover(false)
  }
  const handleClear = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation()
    setOuterFormValue('')
    outerForm.submitForm()
  }
  const handleClearSearchInnerForm = () => {
    innerForm.setValues({search: ''})
  }
  const handleTogglePopover = () => {
    setToggleShowingPopover(!isShowingPopover)
  }
  const handleOnConfirm = () => {
    innerForm.submitForm()
    setToggleShowingPopover(false)
  }

  useEffect(() => {
    innerForm.setValues({search: outerFormValue ?? ''})
  }, [outerFormValue])

  return (
    <FormikProvider value={innerForm}>
      <div id={`text-filter-${name}`}>
        <OverlayTrigger
          rootClose
          trigger='click'
          show={isShowingPopover}
          placement={popperPlacement ?? 'bottom'}
          onToggle={handleTogglePopover}
          overlay={
            <Popover
              id={`text-filter-${name}`}
              bsPrefix='m-0'
              className='menu menu-sub menu-sub-dropdown min-w-360px rounded-2 bg-white'
              style={{zIndex: 4}}
            >
              <div className='card card-custom card-px-0'>
                <div className='card-body p-4'>
                  <div className='card-title m-0 py-4'>
                    <div className='d-flex align-items-center rounded-2 position-relative'>
                      <i className='ki-duotone ki-magnifier fs-2 position-absolute ms-3'>
                        <span className='path1'></span>
                        <span className='path2'></span>
                      </i>
                      <input
                        {...props}
                        name='search'
                        type={type ?? 'text'}
                        autoComplete='off'
                        data-kt-table-filter={`${name}-private-search`}
                        className='form-control form-control-outlined w-100 ps-10 pe-8 fs-7 fw-bold h-40px py-0'
                        placeholder={placeholder ?? 'Tìm kiếm'}
                        value={searchValueWatch}
                        onChange={innerForm.handleChange}
                      />
                      {searchValueWatch ? (
                        <button
                          className='btn p-0 position-absolute end-0 me-2'
                          data-kt-menu-dismiss='false'
                          onClick={handleClearSearchInnerForm}
                        >
                          <i className='ki-duotone ki-cross-circle fs-3'>
                            <span className='path1'></span>
                            <span className='path2'></span>
                          </i>
                        </button>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className='card-footer px-4 py-2'>
                  <div className='d-flex justify-content-end'>
                    <button
                      type='button'
                      className='btn btn-light fw-bold fs-6 px-4 py-3 lh-1 me-3 rounded-2 text-gray-600'
                      data-kt-menu-dismiss='true'
                      onClick={handleClose}
                    >
                      Huỷ
                    </button>
                    <button
                      type='button'
                      className='btn btn-primary fw-bold fs-6 px-4 py-3 lh-1 rounded-2'
                      data-kt-menu-dismiss='true'
                      onClick={handleOnConfirm}
                    >
                      Xác nhận
                    </button>
                  </div>
                </div>
              </div>
            </Popover>
          }
        >
          <button
            type='button'
            className={`${buttonClassName ?? ''} btn btn-sm d-flex align-items-center fw-bold ${
              outerFormValue ? 'bg-light-primary btn-color-primary' : 'btn-light btn-color-gray-600'
            } me-2 rounded-2 py-0 px-3 h-32px`}
            data-kt-menu-trigger='click'
            data-kt-menu-placement={popperPlacement ?? 'bottom'}
          >
            <span className='me-1 mw-160px text-truncate lh-sm'>{searchedLabel}</span>
            {hasSearchValue ? (
              <div className='ms-1' onClick={handleClear}>
                <i className='ki-duotone ki-cross-circle fs-3'>
                  <span className='path1'></span>
                  <span className='path2'></span>
                </i>
              </div>
            ) : (
              <i className='ki-duotone ki-down fs-7'></i>
            )}
          </button>
        </OverlayTrigger>
      </div>
    </FormikProvider>
  )
}

export {TextFilter}
