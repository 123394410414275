/* eslint-disable react-hooks/exhaustive-deps */
import clsx from 'clsx'
import {useFormik, useFormikContext} from 'formik'
import {every, filter, includes, isEqual, isNull, map} from 'lodash'
import React, {FC, Fragment, useEffect, useState} from 'react'
import {
  DragDropContext,
  Draggable,
  DraggingStyle,
  Droppable,
  DropResult,
  NotDraggingStyle,
} from 'react-beautiful-dnd'
import {removeVietnameseAccents} from '../../../../../../../../../_biha/utils'
import {ColumnType, Number} from '../../../../../../../auth'
import {Column} from 'react-table'

interface CheckboxFilterFormInputs {
  all?: string | boolean
  values?: ColumnType[]
}
interface Props {
  columns: ReadonlyArray<Column<any>>
}

const EditViewTableModal: FC<Props> = ({columns}) => {
  const [searchString, setSearchString] = useState('')
  const outerForm = useFormikContext<CheckboxFilterFormInputs>()
  const outerValues = outerForm.values.values
  const defaultValues: string[] = map(columns, (column) => column?.id ?? '')
  const innerForm = useFormik<CheckboxFilterFormInputs>({
    initialValues: Object.assign({}, ...defaultValues.map((key: any) => ({[key]: undefined}))),
    onSubmit: async (values) => {
      outerForm.setFieldValue('values', values.values)
      outerForm.handleSubmit()
    },
  })
  const {values} = innerForm.values
  const isNoChecked = every(values, (value) => isEqual(value?.checked, false))
  const isCheckedAll = every(values, (value) => isEqual(value?.checked, true))
  const reorderColumns = (list: ColumnType[] = [], startIndex: Number, endIndex: Number) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    return result
  }

  const handleOnDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return
    }
    const newColumns = reorderColumns(
      filter(values, (column) => !isEqual(column?.id, 'selection')),
      result.source.index,
      result.destination.index
    )
    innerForm.setFieldValue('values', newColumns)
  }
  const getListStyle = (isDraggingOver: Boolean) => ({
    height: '100%',
    width: '100%',
    overflow: 'auto',
    borderRadius: 8,
    backgroundColor: isDraggingOver ? '#f4f4f4' : 'white',
  })
  const getItemStyle = (
    isDragging: Boolean,
    draggableStyle?: DraggingStyle | NotDraggingStyle
  ) => ({
    userSelect: 'none',
    backgroundColor: isDragging ? '#edf4ff' : 'white',
    borderRadius: 8,
    borderColor: '#dedede',
    borderWidth: 1,
    borderStyle: 'solid',
    marginTop: 16,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: 9,
    paddingTop: 9,
    paddingLeft: 12,
    paddingRight: 12,

    ...draggableStyle,
  })
  const handleOnCheckAll = () => {
    innerForm.setFieldValue(
      'values',
      isCheckedAll
        ? map(values, (value) => ({
            ...value,
            checked: false,
          }))
        : map(values, (value) => ({
            ...value,
            checked: true,
          }))
    )
  }
  const handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchString(e.target?.value)
  }
  const handleOnClearSearch = () => {
    setSearchString('')
  }
  const handleChange = (targetIndex: number, targetItem?: ColumnType) => {
    const cloneValues = [...(values ?? [])]
    cloneValues[targetIndex] = {...cloneValues?.[targetIndex], checked: !targetItem?.checked}
    innerForm.setFieldValue('values', cloneValues)
  }

  useEffect(() => {
    innerForm.setFieldValue('values', outerValues)
  }, [outerValues])

  return (
    <Fragment>
      <div
        className='modal-body p-4 mb-4'
        style={{maxHeight: 548, minHeight: 584, overflow: 'scroll'}}
      >
        <div className='d-flex align-items-center rounded-2 position-relative'>
          <i
            className='ki-duotone ki-magnifier fs-2 position-absolute ms-3'
            onClick={handleOnClearSearch}
          >
            <span className='path1'></span>
            <span className='path2'></span>
          </i>
          <input
            autoComplete='off'
            data-kt-table-filter='edit-view-checkbox-search'
            className='form-control form-control-ontlined w-100 ps-10 pe-8 fs-7 fw-bold h-40px py-0'
            placeholder='Tìm kiếm'
            value={searchString ?? ''}
            onChange={handleChangeSearch}
          />
        </div>
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <div style={{height: '100%'}}>
            <Droppable droppableId={`droppable`}>
              {(provided, snapshot) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={getListStyle(snapshot.isDraggingOver)}
                >
                  <div
                    style={{
                      borderRadius: 8,
                      borderWidth: 1,
                    }}
                  >
                    <div className='mb-3 user-select-all'>
                      <label
                        className={`form-check form-check-custom form-check-solid w-100 cursor-pointer ps-3 pt-4 rounded-2 user-select-none`}
                      >
                        <input
                          id={`checkbox-all`}
                          name='checkedAll'
                          className='form-check-input'
                          checked={isCheckedAll}
                          type='checkbox'
                          value='all'
                          onChange={handleOnCheckAll}
                        />
                        <span className='form-check-label text-gray-600 fs-7 lh-sm fw-bold text-truncate'>
                          Chọn tất cả
                        </span>
                      </label>
                    </div>
                  </div>
                  {map(
                    searchString
                      ? filter(
                          filter(
                            values,
                            (column) => !isEqual(column?.id, 'selection') && !isNull(column?.id)
                          ),
                          (item) =>
                            includes(
                              removeVietnameseAccents(item.label),
                              removeVietnameseAccents(searchString)
                            )
                        )
                      : filter(
                          values,
                          (column) => !isEqual(column?.id, 'selection') && !isNull(column?.id)
                        ),
                    (item, index) => {
                      return (
                        <Draggable
                          key={`draggable-${item?.id}`}
                          draggableId={`draggable-${item?.id ?? index}`}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={
                                getItemStyle(
                                  snapshot.isDragging,
                                  provided.draggableProps.style
                                ) as any
                              }
                            >
                              <label
                                className={`form-check form-check-custom form-check-solid w-100 cursor-pointer rounded-2 user-select-none`}
                              >
                                <input
                                  id={`checkbox`}
                                  name='values'
                                  className='form-check-input'
                                  type='checkbox'
                                  checked={isCheckedAll || item?.checked}
                                  value={item?.id}
                                  onChange={() => handleChange(index, item)}
                                />
                                <span className='form-check-label text-gray-600 fs-7 lh-sm fw-bold text-truncate'>
                                  {item?.label}
                                </span>
                              </label>
                              <div className='d-flex align-items-center justify-content-center'>
                                <i className='ki-outline ki-dots-vertical text-gray-600 fs-2'></i>
                              </div>
                            </div>
                          )}
                        </Draggable>
                      )
                    }
                  )}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </div>
        </DragDropContext>
      </div>
      <div className='d-flex justify-content-end p-2 border border-solid border-gray-300'>
        <button
          disabled={isNoChecked}
          className={clsx(`btn btn-primary fw-bold fs-6 px-4 py-3 lh-1 rounded-2`, {
            'user-select-none ': isNoChecked,
          })}
          data-kt-menu-dismiss='true'
          onClick={() => innerForm.handleSubmit()}
        >
          Xác nhận
        </button>
      </div>
    </Fragment>
  )
}

export {EditViewTableModal}
