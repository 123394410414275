// @ts-nocheck
import {isString} from 'lodash'
import {FC, Fragment} from 'react'
import {ColumnInstance} from 'react-table'

interface Props {
  column: ColumnInstance<any>
}

const HeaderColumn: FC<Props> = ({column}) => (
  <Fragment>
    {isString(column.Header) ? (
      <th {...column.getHeaderProps()}>{column.render('Header')}</th>
    ) : (
      column.render('Header')
    )}
  </Fragment>
)

export {HeaderColumn}
