import { useListView } from '../../core/ListViewProvider'

const ContactFormListGrouping = () => {
  const {selected} = useListView()

  return (
    <div className='d-flex justify-content-end align-items-center'>
      <div className='fw-bolder me-5'>Đã chọn {selected.length}</div>
    </div>
  )
}

export { ContactFormListGrouping }

