import clsx from 'clsx'
import {map, toString} from 'lodash'
import {FC, Fragment, useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {useNavigate} from 'react-router-dom'
import {Row} from 'react-table'
import {MenuComponent} from '../../../../../../../../../../_biha/assets/ts/components'
import {SITE_MAP} from '../../../../../../../../../../_biha/helpers'
import {ContactFormOutput} from '../../../../../../core/_models'
import DeleteModal from './modals/delete-modal/DeleteModal'
import ReplicateModal from './modals/replicate-modal/ReplicateModal'
import RestoreModal from './modals/restore-modal/RestoreModal'

interface Props {
  rows: Row<ContactFormOutput>[]
  index: number
}

const TableRowAction: FC<Props> = ({rows, index}) => {
  const navigate = useNavigate()
  const [isOpenAction, setIsOpenAction] = useState(false)
  const [isShowingDeleteModal, toggleShowingDeleteModal] = useState<boolean>(false)
  const [isShowingReplicateModal, toggleShowingReplicateModal] = useState<boolean>(false)
  const [isShowingRestoreModal, toggleShowingRestoreModal] = useState<boolean>(false)
  const {_id, status} = rows?.[index]?.original ?? {}

  const handleClickAction = () => {
    setIsOpenAction(!isOpenAction)
  }
  const handleEditContactForm = () => {
    navigate(
      `/${SITE_MAP.CONTACT_FORM_MANAGEMENT.INDEX}/${SITE_MAP.CONTACT_FORM_MANAGEMENT.EDIT}/${_id}`
    )
  }
  const handleOnToggleDeleteModal = () => {
    toggleShowingDeleteModal(!isShowingDeleteModal)
  }
  const handleOnToggleReplicateModal = () => {
    toggleShowingReplicateModal(!isShowingReplicateModal)
  }
  const handleOnToggleRestoreModal = () => {
    toggleShowingRestoreModal(!isShowingRestoreModal)
  }

  const buttonData = {
    approved: [
      {
        id: 1,
        title: 'Chỉnh sửa danh mục',
        textColor: 'text-gray-600',
        textHoverColor: 'text-hover-primary',
        action: handleEditContactForm,
      },
      {
        id: 2,
        title: 'Nhân bản',
        textColor: 'text-gray-600',
        textHoverColor: 'text-hover-primary',
        show: isShowingReplicateModal,
        toggle: handleOnToggleReplicateModal,
        modal: <ReplicateModal categoryId={_id} onClose={handleOnToggleReplicateModal} />,
      },
      {
        id: 3,
        title: 'Xóa',
        textColor: 'text-danger',
        textHoverColor: 'text-hover-danger',
        show: isShowingDeleteModal,
        toggle: handleOnToggleDeleteModal,
        modal: <DeleteModal categoryId={_id} onClose={handleOnToggleDeleteModal} />,
      },
    ],
    processcing: [
      {
        id: 1,
        title: 'Chỉnh sửa danh mục',
        textColor: 'text-gray-600',
        textHoverColor: 'text-hover-primary',
        action: handleEditContactForm,
      },
      {
        id: 2,
        title: 'Khôi phục',
        textColor: 'text-danger',
        textHoverColor: 'text-hover-danger',
        show: isShowingRestoreModal,
        toggle: handleOnToggleRestoreModal,
        modal: <RestoreModal categoryId={_id} onClose={handleOnToggleRestoreModal} />,
      },
    ],
    rejected: [],
  }
  const buttonsByPathname = (buttonData as any)[toString(status)]

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  return (
    <Fragment>
      <div
        className='position-absolute translate-middle-y h-56px w-148px d-flex align-items-center justify-content-end'
        style={{
          top: 56.5 + index * 56 + 28,
          right: 0,
          backgroundImage: 'linear-gradient(270deg, #ffffff, #ffffff80)',
        }}
      >
        <button
          className={clsx('btn btn-icon p-3 w-auto bg-hover-light-primary text-hover-primary', {
            'bg-light text-gray-600': !isOpenAction,
            'bg-light-primary text-primary': isOpenAction,
          })}
          data-kt-menu-trigger='click'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='top-end'
          onClick={handleClickAction}
        >
          <span className='fw-bold fs-7'>Thao tác</span>
          <span className='ki-duotone ki-down mx-1 fs-4'>
            <span className='path1' />
          </span>
        </button>
        <div
          className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary w-216px'
          data-kt-menu='true'
        >
          <div className='py-3 px-2 d-flex flex-column'>
            {map(buttonsByPathname, (button) => {
              if (button?.action) {
                return (
                  <button
                    key={button?.id}
                    type='button'
                    className={`${button?.textColor} ${button?.textHoverColor} btn p-3 d-flex bg-hover-light-primary align-items-center rounded-1`}
                    onClick={button.action}
                  >
                    <span className='fw-medium fs-6'>{button?.title}</span>
                  </button>
                )
              } else {
                return (
                  <Fragment key={button?.id}>
                    <button
                      type='button'
                      className={`${button?.textColor} ${button?.textHoverColor} btn p-3 d-flex bg-hover-light-primary align-items-center rounded-1`}
                      onClick={button.toggle}
                    >
                      <span className='fw-medium fs-6'>{button?.title}</span>
                    </button>
                    <Modal
                      show={button.show}
                      onHide={button.toggle}
                      tabIndex={-1}
                      dialogClassName='modal-dialog modal-dialog-centered w-400px'
                    >
                      {button.modal}
                    </Modal>
                  </Fragment>
                )
              }
            })}
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export {TableRowAction}
