import { ContactFormListGrouping } from './components/header/ContactFormListGrouping'
import { ContactFormListToolbar } from './components/header/ContactFormListToolbar'
import { ContactFormListSearchTool } from './components/header/search-tool/ContactFormListSearchTool'
import { useListView } from './core/ListViewProvider'

const ContactFormManagementListHeader = () => {
  const {selected} = useListView()

  return (
    <div className='card-header border-0 pt-8 m-n3'>
      <div className='d-flex p-3'>
        <ContactFormListSearchTool />
      </div>
      <div className='card-toolbar p-3'>
        {selected.length > 0 ? <ContactFormListGrouping /> : <ContactFormListToolbar />}
      </div>
    </div>
  )
}

export default ContactFormManagementListHeader
