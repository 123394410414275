import {useEffect} from 'react'
import {useLocation} from 'react-router'
import clsx from 'clsx'
import {useLayout} from '../../core'
import {DrawerComponent} from '../../../assets/ts/components'
import {WithChildren} from '../../../helpers'

interface Props extends WithChildren {
  container?: 'fluid' | 'fixed'
}

const Content = ({container = 'fluid', children}: Props) => {
  const {config, classes} = useLayout()
  const location = useLocation()

  useEffect(() => {
    DrawerComponent.hideAll()
  }, [location])

  return (
    <div
      id='kt_app_content'
      className={clsx(
        'app-content flex-column-fluid',
        classes.content.join(' '),
        config?.app?.content?.class
      )}
    >
      <div
        id='kt_app_content_container'
        className={clsx('app-container', classes.contentContainer.join(' '), {
          'container-xxl': container === 'fixed',
          'container-fluid': container === 'fluid',
        })}
      >
        {children}
      </div>
    </div>
  )
}

export {Content}
