/* eslint-disable react-hooks/exhaustive-deps */
import {
  gt,
  isEmpty,
  isEqual,
  isNil,
  isSafeInteger,
  lt,
  max,
  min,
  replace,
  toNumber,
  toString,
} from 'lodash'
import qs from 'qs'
import { FC, useContext, useEffect, useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import { useLocation } from 'react-router-dom'
import {
  QUERIES,
  WithChildren,
  createPaginationResponseContext,
  initialQueryResponse,
  stringifyRequestQuery,
} from '../../../../../../_biha/helpers'
import { CategoriesPagination, CategoriesQueryResponse } from '../../core/_models'
import { categories } from '../../core/_requests'

const QueryResponseContext =
  createPaginationResponseContext<CategoriesQueryResponse>(initialQueryResponse)
const QueryResponseProvider: FC<WithChildren> = ({children}) => {
  const {search, pathname} = useLocation()
  const searchObject = qs.parse(replace(search, '?', ''))
  const [query, setQuery] = useState<any>(searchObject)
  const updatedQuery = useMemo(() => stringifyRequestQuery(searchObject), [searchObject])
  const {
    perPage: perPageParam,
    page: pageParam,
    createdAt: createdAtParam,
    updatedAt: updatedAtParam,
    ...restFilterParams
  } = searchObject

  const convertedSearchObject = {
    ...restFilterParams,
    isDeleted: false,
    pagination: {
      page:
        !isEmpty(pageParam) && !isNaN(toNumber(pageParam)) && isSafeInteger(toNumber(pageParam))
          ? toNumber(pageParam)
          : 1,
      perPage:
        !isEmpty(perPageParam) &&
        !isNaN(toNumber(perPageParam)) &&
        isSafeInteger(toNumber(perPageParam))
          ? toNumber(perPageParam)
          : 10,
    },
    createdAt: !isNil(createdAtParam)
      ? `${min(createdAtParam as string)}, ${max(createdAtParam as string)}`
      : undefined,
    updatedAt: !isNil(updatedAtParam)
      ? `${min(updatedAtParam as string)}, ${max(updatedAtParam as string)}`
      : undefined,
  }
  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery(
    [`${QUERIES.CONTACT_FORM_LIST}-${query}`, pathname],
    () => categories(convertedSearchObject),
    {
      cacheTime: 0,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  )

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery)
    }
  }, [updatedQuery, pathname])

  return (
    <QueryResponseContext.Provider value={{isLoading: isFetching, refetch, response, query}}>
      {children}
    </QueryResponseContext.Provider>
  )
}
const useQueryResponse = () => useContext(QueryResponseContext)
const useQueryResponseData = () => {
  const {response} = useQueryResponse()
  const {data} = response ?? {}
  if (!data?.categories?.data) {
    return []
  }
  return data.categories?.data
}
const useQueryResponseLoading = (): boolean => {
  const {isLoading} = useQueryResponse()
  return isLoading
}
const useQueryResponsePagination = () => {
  const defaultPaginationState: CategoriesPagination = {
    links: [],
    currentPage: 1,
    perPage: 10,
  }
  const {response} = useQueryResponse()
  const {currentPage, perPage, totalData, totalPage} = response?.data?.categories ?? {}
  const pagination: CategoriesPagination = {
    currentPage,
    perPage,
    totalData,
    totalPage,
    links: [
      {
        active: false,
        disabled: isEqual(currentPage, 1),
        label: '<',
        page: isEqual(currentPage, 1) ? undefined : currentPage ? currentPage - 1 : 1,
      },
      {
        active: isEqual(currentPage, 1),
        disabled: false,
        label: totalPage ? '1' : undefined,
        page: totalPage ? 1 : undefined,
      },
      {
        active: isEqual(currentPage, 2),
        disabled: false,
        label: lt(totalPage, 2)
          ? undefined
          : lt(currentPage, totalPage) && gt(currentPage, 3)
          ? '...'
          : '2',
        page: lt(totalPage, 2)
          ? undefined
          : lt(currentPage, totalPage) && gt(currentPage, 3)
          ? undefined
          : 2,
      },
      {
        active:
          (gt(currentPage, 2) && !isEqual(currentPage, totalPage)) ||
          (gt(currentPage, 2) && isEqual(currentPage, 3) && isEqual(currentPage, totalPage)),
        disabled: false,
        label: lt(totalPage, 3)
          ? undefined
          : lt(currentPage, totalPage) && gt(currentPage, 3)
          ? toString(currentPage)
          : '3',
        page: lt(totalPage, 3)
          ? undefined
          : lt(currentPage, totalPage) && gt(currentPage, 3)
          ? currentPage
          : 3,
      },
      {
        active: false,
        disabled: false,
        label: 'input',
        page: undefined,
      },
      {
        active: isEqual(currentPage, totalPage),
        disabled: false,
        label: lt(totalPage, 4) ? undefined : toString(totalPage),
        page: lt(totalPage, 4) ? undefined : totalPage,
      },
      {
        active: false,
        disabled: isEqual(currentPage, totalPage),
        label: '>',
        page: isEqual(currentPage, totalPage) ? undefined : currentPage ? currentPage + 1 : 1,
      },
    ],
  }
  if (!currentPage && !totalPage && !perPage && !totalData) {
    return defaultPaginationState
  }
  return pagination
}

export {
  QueryResponseProvider,
  useQueryResponse,
  useQueryResponseData,
  useQueryResponseLoading,
  useQueryResponsePagination
}

