/* eslint-disable react-hooks/exhaustive-deps */
import {FormikProvider, useFormik} from 'formik'
import {filter, find, isEqual, map, toString} from 'lodash'
import {FC, useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {ColumnType, useAuth} from '../../../../../../auth'
import {updateTableSettings} from '../../../../core/_requests'
import {EditViewTableModal} from './edit-view-modal/EditViewTableModal'
import {Column} from 'react-table'

export interface CheckboxFilterFormInputs {
  values?: ColumnType[]
}
interface Props {
  tableId: string
  columnObjects: ColumnObject[]
  columns: ReadonlyArray<Column<any>>
}
interface ColumnObject {
  label: string
  id: string
}

const EditViewTable: FC<Props> = ({tableId, columnObjects, columns}) => {
  const [isShowingModal, toggleShowingModal] = useState<boolean>(false)
  const {currentUser, setCurrentUser} = useAuth()
  const {tableSettings} = currentUser ?? {}
  const orderedColumns = find(tableSettings, {tableId})?.columns
  const defaultValues: string[] = map(columnObjects, (column) => column?.id ?? '')
  const form = useFormik<CheckboxFilterFormInputs>({
    initialValues: Object.assign({}, ...defaultValues.map((key: any) => ({[key]: undefined}))),
    onSubmit: async (values: CheckboxFilterFormInputs) => {
      const data: ColumnType[] = map(values.values, (column) => ({
        ...column,
      }))
      const filteredData = filter(data, (item) => !isEqual(item?.id, 'selection'))
      updateTableSettings({
        input: {
          tableId,
          columns: filteredData,
        },
      })

      const removedTargetItem = filter(
        tableSettings,
        (tableSetting) => !isEqual(tableSetting.tableId, tableId)
      )
      setCurrentUser({
        ...currentUser,
        tableSettings: [...removedTargetItem, {tableId, columns: filteredData}],
      })
      handleOnToggleModal()
    },
  })

  const handleOnToggleModal = () => {
    toggleShowingModal(!isShowingModal)
  }

  useEffect(() => {
    const defaultColumns = orderedColumns
      ? map(orderedColumns, (column) => ({
          id: column?.id,
          label: toString(column?.label),
          checked: find(orderedColumns, {id: column?.id})?.checked,
        }))
      : map(columnObjects, (column) => ({
          id: column.id,
          label: toString(column?.label),
          checked: true,
        }))

    form.setFieldValue('values', defaultColumns)
  }, [columnObjects, isShowingModal])

  return (
    <FormikProvider value={form}>
      <button
        type='button'
        className='btn btn-lg fw-semibold btn-light-primary ms-5 rounded-2 py-0 px-3 h-40px'
        onClick={handleOnToggleModal}
      >
        <i className='ki-duotone ki-setting-4 fs-1'>
          <span className='path1'></span>
          <span className='path2'></span>
        </i>
        Chỉnh sửa hiển thị
      </button>
      <Modal
        show={isShowingModal}
        onHide={handleOnToggleModal}
        tabIndex={-1}
        dialogClassName='modal-dialog modal-dialog-centered w-500px'
      >
        <div className='modal-header h-60px d-flex justify-content-end align-items-center p-4 position-relative border-0'>
          <h5 className='modal-title position-absolute top-50 start-50 translate-middle fs-3'>
            Chỉnh sửa hiển thị
          </h5>
          <div
            className='btn btn-icon btn-sm btn-active-light-primary ms-2'
            onClick={handleOnToggleModal}
          >
            <i className='ki-duotone ki-cross fs-2x'>
              <span className='path1'></span>
              <span className='path2'></span>
            </i>
          </div>
        </div>
        <EditViewTableModal columns={columns} />
      </Modal>
    </FormikProvider>
  )
}

export {EditViewTable}
