import {ResponseGraphQL} from '../../../../../_biha/helpers'
import {
  AddressType,
  B2BUserType,
  Boolean,
  DateTime,
  FileWithFullUrls,
  Float,
  ID,
  Int,
  ObjectID,
  String,
} from '../../../auth'
import {CareerEnum, PaginationOf} from '../../core/_models'
import {MutationOf} from '../../shared/comment/core/_model'

export enum TagStatusEnum {
  active = 'active',
  stop = 'stop',
  draft = 'draft',
}
export enum SocialUserTypeEnum {
  Individual = 'Individual',
  Enterprise = 'Enterprise',
}
export enum IndustryGroupEnum {
  BUSINESSES = 'BUSINESSES',
  GOVERNMENT_DEPARTMENTS = 'GOVERNMENT_DEPARTMENTS',
  PROVINCES_CITIES = 'PROVINCES_CITIES',
  ASSOCIATIONS = 'ASSOCIATIONS',
  EXPERTS = 'EXPERTS',
}
export enum GenderEnumType {
  male = 'male',
  female = 'female',
  other = 'other',
}
export enum MemberStatusEnum {
  PROCESSING = 'PROCESSING',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  PENDING = 'PENDING',
}
export enum TollStatusEnum {
  FEES_NOT_PAID = 'FEES_NOT_PAID',
  FEES_PAID = 'FEES_PAID',
  STOP = 'STOP',
}
export enum TypeEnum {
  post = 'post',
  category = 'category',
  banner = 'banner',
  tag = 'tag',
}
export type TagsQueryResponse = ResponseGraphQL<TagsQueryData>

//for filter form
export interface TagListFilterForm {
  title?: String
  createdAt?: String[]
  updatedAt?: String[]
  updatedBy?: String[]
  createdBy?: String[]
}
export const tagListFilterDefaultValues: TagListFilterForm = {
  title: '',
  updatedAt: [],
  updatedBy: [],
  createdBy: [],
  createdAt: [],
}
//table
export interface ColumnInput {
  checked?: Boolean
  id?: String
  label?: String
}
export interface TableInput {
  tableId: String
  columns: ColumnInput[]
}
export interface UpdateTableSettingsQueryVariables {
  input: TableInput
}
export interface UpdateTableSettingsQueryData {
  updateTableSettings?: {
    success?: Boolean
    msg?: String
    payload?: String
  }
}

//new-tag
export interface TagInput {
  title?: String
  slug?: String
  status?: String
}
export interface CreateTagVariables {
  data: TagInput
}
export interface CreateTagData {
  createTag?: TagMutationResponse
}
export interface PaginationListDtoDto {
  page?: Float
  perPage?: Float
  search?: String
}
export interface TagsQueryVariables {
  search?: String
  title?: String
  slug?: String
  pagination?: PaginationListDtoDto
  status?: TagStatusEnum[]
  createdBy?: String[]
  createdAt?: String
  isDeleted?: Boolean
}
export interface AvatarType {
  fileId?: String
  originalUrl?: String
}
export interface User {
  fullName: String
  userId: ID
  avatar: AvatarType
  phone: String
}

export interface Tag {
  _id?: String
  title?: String
  slug?: String
  status?: TagStatusEnum
  createdAt?: DateTime
  updatedAt?: DateTime
  createdBy?: User
  updatedBy?: User
}

export interface TagsPagination {
  totalData?: Float
  totalPage?: Float
  currentPage?: Float
  perPage?: Float
  _availableSearch?: String[]
  _availableSort?: String[]
  hasNextPage?: Boolean
  links?: {
    active: Boolean
    disabled: Boolean
    label: String | undefined
    page: Number | undefined
  }[]
}
export interface TagsQueryData {
  tags?: TagsQueryPayload
}

export interface TagsQueryPayload extends Omit<TagsPagination, 'links'> {
  data?: Tag[]
}
export interface GetListUserUnionInfoByCompanyVariables {
  isUpdatedBy?: Boolean
  limit?: Int
  lastTime?: DateTime
  search?: String
  ids?: ObjectID[]
}
export interface RefUserType
  extends Pick<
    B2BUserType,
    | 'id'
    | 'birthDate'
    | 'createdAt'
    | 'email'
    | 'fullName'
    | 'gender'
    | 'phone'
    | 'province'
    | 'updatedAt'
    | 'avatar'
  > {
  companyFields?: String[]
  developer?: String
  madeCommissions?: Float
  memberId?: String
  phones?: String[]
  taxId?: String
  type?: String
  statusProfile?: String
  typeSocialUser?: SocialUserTypeEnum
}
export interface MemberOutputType {
  attachments?: FileWithFullUrls[]
  approvedAt?: DateTime
  approvedBy?: B2BUserType
  avatar?: FileWithFullUrls
  bank?: String
  bankAccountNumber?: String
  career?: CareerEnum
  code?: String
  company?: String
  createdAt?: DateTime
  createdBy?: B2BUserType
  dateOfBirth?: Date
  developer?: SocialUserOutputType
  district?: AddressType
  email?: String
  faxNumber?: String
  fullName?: String
  gender?: GenderEnumType
  houseNumber?: String
  id?: String
  idNumber?: String
  imgCard?: FileWithFullUrls[]
  industryGroup?: IndustryGroupEnum
  phone?: String
  phones?: String[]
  processedAt?: DateTime
  processedBy?: B2BUserType
  province?: AddressType
  rejectedAt?: DateTime
  rejectedBy?: B2BUserType
  representative?: RepresentativeOutputType
  roleMember?: MembershipRoleType
  roleMemberId?: ObjectID
  reason?: String
  status?: MemberStatusEnum
  street?: AddressType
  taxId?: String
  typeSocialUser?: SocialUserTypeEnum
  tollStatus?: TollStatusEnum
  updatedAt?: DateTime
  updatedBy?: B2BUserType
  userId?: B2CUser
  ward?: AddressType
}
export interface SocialUserOutputType {
  avatar?: FileWithFullUrls
  bank?: String
  bankAccountNumber?: String
  career?: CareerEnum
  company?: String
  createdAt?: DateTime
  createdBy?: B2BUserType
  dateOfBirth?: Date
  developer?: String
  developerMember?: RefUserType
  district?: AddressType
  email?: String
  faxNumber?: String
  fullName?: String
  gender?: String
  houseNumber?: String
  id?: String
  idNumber?: String
  imgCard?: FileWithFullUrls[]
  industryGroup?: IndustryGroupEnum
  isActive?: Boolean
  isMembership?: Boolean
  member?: MemberOutputType
  memberId?: String
  phone?: String
  phones?: String[]
  province?: AddressType
  representative?: RepresentativeOutputType
  roleMember?: MembershipRoleType
  status?: MemberStatusEnum
  street?: AddressType
  taxId?: String
  type?: String
  typeSocialUser?: SocialUserTypeEnum
  updatedAt?: DateTime
  updatedBy?: B2BUserType
  userType?: String
  ward?: AddressType
}
type ListUserUnionPagination = PaginationOf & {items?: SocialUserOutputType[]}

export interface GetListUserUnionInfoByCompanyData {
  getListUserUnionInfoByCompany?: ListUserUnionPagination
}
export interface RepresentativeOutputType {
  avatar?: FileWithFullUrls
  dateOfBirth?: Date
  district?: AddressType
  email?: String
  fullName?: String
  gender?: GenderEnumType
  houseNumber?: String
  idNumber?: String
  imgCard?: FileWithFullUrls[]
  phones?: String[]
  province?: AddressType
  street?: AddressType
  ward?: AddressType
}
export interface MembershipRoleType {
  allowableLimit?: Int
  createdAt?: DateTime
  createdBy?: B2BUserType
  description?: String
  fees?: Boolean
  id?: ID
  renewalFee?: Float
  roleName?: String
  updatedAt?: DateTime
  updatedBy?: B2BUserType
}
export interface B2CUser {
  id?: String
  fullName?: String
  phones?: String[]
  email?: String
  avatar?: {
    fileId?: String
    originalUrl?: String
  }
  memberId?: String
}

export interface ReplicateTagVariables {
  id: String
}
export interface DeleteTagVariables {
  id: String
}
export interface RestoreTagVariables {
  id: String
}
export interface RestoreTagData {
  restoreTag?: TagMutationResponse
}
export interface DeleteTagData {
  deleteTag?: TagMutationResponse
}
export interface ReplicateTagData {
  replicateTag?: TagMutationResponse
}
export interface TagMutationResponse extends MutationOf {
  payload?: Tag
}
export interface TagVariables {
  id: String
}
export interface TagData {
  tag?: TagMutationResponse
}
export interface UpdateTagVariables {
  id: String
  data: TagInput
}

export interface UpdateTagData {
  updateTag?: TagMutationResponse
}
