const QUERIES = {
  CONFIG: 'config',
  PROVINCE_LIST: 'province-list',
  DISTRICT_LIST: 'district-list',
  WARD_LIST: 'ward-list',
  STREET_LIST: 'street-list',
  COMMENT: 'comment',
  EDIT_HISTORY: 'edit-history',
  INTERACTION_HISTORY: 'interaction-history',
  USER_CONFIG: 'user-config',

  CREATED_BY_LIST: 'created-list',
  UPDATED_BY_LIST: 'updated-list',
  EDITED_BY_LIST: 'edited-list',
  APPROVED_BY_LIST: 'approved-list',
  PROCESSED_BY_LIST: 'processed-list',
  REJECTED_BY_LIST: 'rejected-list',
  MEMBER_ROLE_LIST: 'member-role-list',
  CREATED_AND_UPDATED_BY: 'created-and-updated-by',
  //CMS

  //tags
  TAG_LIST: 'tag-list',
  //banners
  BANNER_LIST: 'banner-list',
  ARTICLE_LIST: 'article-list',
  ARTICLE_CREATED_BY_LIST: 'article-created-list',
  ARTICLE_EDITED_BY_LIST: 'article-edited-list',
  CATEGORY_LIST: 'category-list',
  CATEGORY_TREE_LIST: 'category-tree-list',
  CATEGORY_CREATED_BY_LIST: 'category-created-list',
  CATEGORY_EDITED_BY_LIST: 'category-edited-list',
  FAQ_LIST: 'faq-list',
  SUBCRIBE_NEWSLETTER_LIST: 'subcribe-newsletter-list',
  // M&A
  ARTICLE_MNA_LIST: 'article-mna-list',
  CONTACT_FORM_LIST: 'cotact-form-list',
  CATEGORY_MNA_LIST: 'category-mna-list',
  CATEGORY_MNA_TREE_LIST: 'category-tree-list',
  CATEGORY_MNA_CREATED_BY_LIST: 'category-created-list',
}
const SITE_MAP = {
  ARTICLE_MANAGEMENT: {
    INDEX: 'article-management',
    LIST: 'list',
    DELETED_LIST: 'deleted-list',
    SLUG_ID: ':id',
    NEW: 'new',
    EDIT: 'edit',
  },
  CATEGORY_MANAGEMENT: {
    INDEX: 'category-management',
    LIST: 'list',
    DELETED_LIST: 'deleted-list',
    SLUG_ID: ':id',
    NEW: 'new',
    EDIT: 'edit',
  },
  TAG_MANAGEMENT: {
    INDEX: 'tag-management',
    LIST: 'list',
    LIST_DELETED: 'deleted-list',
    EDIT: 'edit',
    NEW: 'new',
    SLUG_ID: ':id',
  },
  BANNER_MANAGEMENT: {
    INDEX: 'banner-management',
    LIST: 'list',
    SLUG_ID: ':id',
    LIST_DELETED: 'deleted-list',
    NEW: 'new',
    EDIT: 'edit',
  },
  FAQ_MANAGEMENT: {
    INDEX: 'faq-management',
    LIST: 'list',
    DELETED_LIST: 'deleted-list',
    SLUG_ID: ':id',
    NEW: 'new',
    EDIT: 'edit',
  },
  SUBSCRIBE_NEWSLETTER_MANAGEMENT: {
    INDEX: 'subcribe-newsletter-management',
    LIST: 'list',
    DELETED_LIST: 'deleted-list',
    SLUG_ID: ':id',
    NEW: 'new',
    EDIT: 'edit',
  },
  ARTICLE_MNA_MANAGEMENT: {
    INDEX: 'article-mna-management',
    LIST: 'list',
    DELETED_LIST: 'deleted-list',
    SLUG_ID: ':id',
    NEW: 'new',
    EDIT: 'edit',
  },
  CONTACT_FORM_MANAGEMENT: {
    INDEX: 'contact-form-management',
    LIST: 'list',
    SLUG_ID: ':id',
    EDIT: 'edit',
  },
  CATEGORY_MNA_MANAGEMENT: {
    INDEX: 'category-mna-management',
    LIST: 'list',
    DELETED_LIST: 'deleted-list',
    SLUG_ID: ':id',
    NEW: 'new',
    EDIT: 'edit',
  },
  CONFIG_MANAGEMENT: {
    INDEX: 'config-management',
    HOME_PAGE: 'home-page',
    POSTING_PRICE: 'posting-price',
    MAP_PRICE: 'map-price',
    LIST: 'list',
    DETAIL: 'detail'
  },
}

const BANK_QUERIES = {
  BANK_LIST: 'bank-list',
}
const USER_CONFIG_QUERIES = {
  USER_CONFIG: 'user-config',
}
const SPECIAL_KEYS = [
  'Tab',
  'ArrowLeft',
  'ArrowRight',
  'ArrowTop',
  'ArrowBottom',
  'Delete',
  'Backspace',
  'Enter',
]

export { BANK_QUERIES, QUERIES, SITE_MAP, SPECIAL_KEYS, USER_CONFIG_QUERIES }

