import clsx from 'clsx'
import {useField} from 'formik'
import {FC, InputHTMLAttributes} from 'react'

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  name: string
  wrapperClassName?: string
}
const InputText: FC<Props> = ({name, wrapperClassName, ...props}) => {
  const {disabled} = props
  const [field, , helpers] = useField(name)
  const valueWatch = field.value ?? ''
  const handleClear = () => {
    helpers.setValue('')
  }
  return (
    <div id={`input-text-${name}`} className='position-relative'>
      <input
        {...props}
        disabled={disabled}
        name={name}
        value={valueWatch}
        autoComplete='off'
        className={clsx(
          wrapperClassName,
          'form-control flex-grow-1 h-40px text-gray-600 fs-7 fw-bold pe-10'
        )}
      />
      {!disabled && valueWatch ? (
        <button
          className='position-absolute btn p-0 top-50 end-0 translate-middle'
          onClick={handleClear}
        >
          <i className='ki-duotone ki-cross-circle fs-3'>
            <span className='path1'></span>
            <span className='path2'></span>
          </i>
        </button>
      ) : null}
    </div>
  )
}

export {InputText}
