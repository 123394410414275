import clsx from 'clsx'
import {useEffect, useState} from 'react'
import {useQueryResponse} from '../../../core/QueryResponseProvider'

const ContactFormListFilter = () => {
  const {isLoading} = useQueryResponse()
  const [isOpenFilter, setToggleFilter] = useState(false)

  const handleToggleFilter = () => {
    setToggleFilter(!isOpenFilter)
  }

  useEffect(() => {
    const element = document.getElementById('contact-form-management-filter-list')
    if (element) {
      element.style.maxHeight = isOpenFilter ? 'fit-content' : '0'
    }
  }, [isOpenFilter])

  return (
    <button
      disabled={isLoading}
      type='button'
      className={clsx('text-gray-800 btn fw-semibold rounded-2 py-0 px-3 h-40px', {
        'bg-gray-300 bg-hover-light': isOpenFilter,
        'btn-light': !isOpenFilter,
      })}
      onClick={handleToggleFilter}
    >
      <i className='ki-duotone ki-filter fs-2'>
        <span className='path1'></span>
        <span className='path2'></span>
      </i>
      Bộ lọc
    </button>
  )
}

export {ContactFormListFilter}
