import {FC, Suspense, lazy} from 'react'
import {Navigate, Route, Routes} from 'react-router-dom'
import TopBarProgress from 'react-topbar-progress-indicator'
import {getCSSVariableValue} from '../../_biha/assets/ts/_utils'
import {SITE_MAP, WithChildren} from '../../_biha/helpers'
import {MasterLayout} from '../../_biha/layout/MasterLayout'
import {ContactFormManagementPage} from '../modules/apps/contact-form-management/ContactFormManagementPage'

const PrivateRoutes = () => {
  const TagManagementPage = lazy(() =>
    import('../modules/apps/tag-management/TagManagementPage').then((module) => ({
      default: module.TagManagementPage,
    }))
  )
  const BannerManagementPage = lazy(() =>
    import('../modules/apps/banner-management/BannerManagementPage').then((module) => ({
      default: module.BannerManagementPage,
    }))
  )
  const ArticleManagementPage = lazy(() =>
    import('../modules/apps/article-management/ArticleManagementPage').then((module) => ({
      default: module.ArticleManagementPage,
    }))
  )
  const CategoryManagementPage = lazy(() =>
    import('../modules/apps/category-management/CategoryManagementPage').then((module) => ({
      default: module.CategoryManagementPage,
    }))
  )
  const ArticleMnAManagementPage = lazy(() =>
    import('../modules/apps/article-mna-management/ArticleMnAManagementPage').then((module) => ({
      default: module.ArticleMnAManagementPage,
    }))
  )
  const CategoryMnAManagementPage = lazy(() =>
    import('../modules/apps/category-mna-management/CategoryMnAManagementPage').then((module) => ({
      default: module.CategoryMnAManagementPage,
    }))
  )

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route
          path='auth/*'
          element={
            <Navigate
              to={`/${SITE_MAP.ARTICLE_MANAGEMENT.INDEX}/${SITE_MAP.ARTICLE_MANAGEMENT.LIST}`}
            />
          }
        />
        <Route
          path={`${SITE_MAP.ARTICLE_MANAGEMENT.INDEX}/*`}
          element={
            <SuspensedView>
              <ArticleManagementPage />
            </SuspensedView>
          }
        />
        <Route
          path={`${SITE_MAP.CATEGORY_MANAGEMENT.INDEX}/*`}
          element={
            <SuspensedView>
              <CategoryManagementPage />
            </SuspensedView>
          }
        />
        <Route
          path={`${SITE_MAP.TAG_MANAGEMENT.INDEX}/*`}
          element={
            <SuspensedView>
              <TagManagementPage />
            </SuspensedView>
          }
        />
        <Route
          path={`${SITE_MAP.BANNER_MANAGEMENT.INDEX}/*`}
          element={
            <SuspensedView>
              <BannerManagementPage />
            </SuspensedView>
          }
        />
        <Route
          path={`${SITE_MAP.ARTICLE_MNA_MANAGEMENT.INDEX}/*`}
          element={
            <SuspensedView>
              <ArticleMnAManagementPage />
            </SuspensedView>
          }
        />
        <Route
          path={`${SITE_MAP.CONTACT_FORM_MANAGEMENT.INDEX}/*`}
          element={
            <SuspensedView>
              <ContactFormManagementPage />
            </SuspensedView>
          }
        />
        <Route
          path={`${SITE_MAP.CATEGORY_MNA_MANAGEMENT.INDEX}/*`}
          element={
            <SuspensedView>
              <CategoryMnAManagementPage />
            </SuspensedView>
          }
        />
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
