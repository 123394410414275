import {useContext} from 'react'
import {useQueryClient} from 'react-query'
import {QUERIES} from '../../../../../../../../../../../../_biha/helpers'
import {String} from '../../../../../../../../../../auth'
import {ToastContext} from '../../../../../../../../../components/toast/Toast'
import {deleteCategory} from '../../../../../../../../core/_requests'
import {useQueryResponse} from '../../../../../../../core/QueryResponseProvider'

interface Props {
  categoryId?: String
  onClose?: () => void
}

const DeleteModal = ({categoryId, onClose}: Props) => {
  const queryClient = useQueryClient()
  const {query} = useQueryResponse()
  const {showToast} = useContext(ToastContext)

  const handleSubmit = async () => {
    try {
      if (categoryId) {
        const response = await deleteCategory({id: categoryId})
        onClose?.()
        if (response?.data?.deleteCategory?.success) {
          queryClient.invalidateQueries([`${QUERIES.CONTACT_FORM_LIST}-${query}`])
          showToast?.('success', 'Thành công', 'Xóa danh mục thành công!')
        } else {
          showToast?.('error', 'Thất bại', `${response?.errors[0]?.message}`)
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div className='modal-content w-412px py-6'>
      <div className='d-flex justify-content-end align-items-center px-6'>
        <div className='btn btn-icon btn-sm btn-active-light-primary ms-2' onClick={onClose}>
          <i className='ki-duotone ki-cross fs-2x'>
            <span className='path1' />
            <span className='path2' />
          </i>
        </div>
      </div>
      <div className='d-flex flex-column align-items-center px-15 py-12'>
        <i className='ki-outline ki-question-2 fs-5hx text-primary' />
        <span className='mt-6 text-gray-600 fw-semibold fs-6 text-center lh-sm'>
          Bạn có chắc muốn xoá danh mục này?
        </span>
      </div>
      <div className='px-6 d-flex justify-content-center'>
        <button type='button' className='btn btn-light text-gray-600' onClick={onClose}>
          Huỷ
        </button>
        <button type='button' className='ms-4 btn btn-primary' onClick={handleSubmit}>
          Xóa danh mục
        </button>
      </div>
    </div>
  )
}

export default DeleteModal
