import axios, {AxiosResponse} from 'axios'
import {CreatedAndUpdatedByData, CreatedAndUpdatedByVariables} from './_models'
import {ResponseGraphQL} from '../../../../../../../../_biha/helpers'

const AUTH_API_ENDPOINT = process.env.REACT_APP_AUTH_API_ENDPOINT ?? ''
const TAG_ENDPOINT = `${AUTH_API_ENDPOINT}/cms/graphql`
const getCreatedAndUpdatedBy = async (variables: CreatedAndUpdatedByVariables) => {
  const response: AxiosResponse<ResponseGraphQL<CreatedAndUpdatedByData>> = await axios.post(
    TAG_ENDPOINT,
    {
      query: `
      query createdAndUpdatedBy($type: TypeEnum) {
        createdAndUpdatedBy(type: $type) {
          createdBy
          updatedBy
        }
      }
    `,
      variables,
    }
  )
  return response.data
}

export {getCreatedAndUpdatedBy}
