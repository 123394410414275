/* eslint-disable react-hooks/exhaustive-deps */
import clsx from 'clsx'
import {toString} from 'lodash'
import {FC, PropsWithChildren} from 'react'
import {HeaderProps} from 'react-table'
import {ContactFormOutput} from '../../../../../core/_models'

interface Props {
  className?: string
  title?: string
  tableProps: PropsWithChildren<HeaderProps<ContactFormOutput>>
}

const BaseHeader: FC<Props> = ({className, title, tableProps}) => {
  return (
    <th
      {...tableProps.column.getHeaderProps()}
      className={clsx('text-nowrap', className)}
      style={{verticalAlign: 'middle'}}
      dangerouslySetInnerHTML={{__html: toString(title)}}
    />
  )
}

export {BaseHeader}
