import {ResponseGraphQL} from '../../../../../_biha/helpers'
import {
  B2BUserType,
  Boolean,
  DateTime,
  FileWithFullUrls,
  Float,
  ID,
  Int,
  ObjectID,
  String,
} from '../../../auth'
import {AddressType, PaginationGraphQLState} from '../../article-management/core/_models'
import {PaginationOf} from '../../core/_models'

//enum
export enum CategoryStatusEnum { //todo: xóa nếu không dùng
  active = 'active',
  stop = 'stop',
  draft = 'draft',
}
export enum SocialUserTypeEnum {
  Individual = 'Individual',
  Enterprise = 'Enterprise',
}
export enum MemberStatusEnum {
  PROCESSING = 'PROCESSING',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  PENDING = 'PENDING',
}
export enum CareerEnum {
  FOOD = 'FOOD',
  AUTOMOTIVE = 'AUTOMOTIVE',
  JOB = 'JOB',
  PET = 'PET',
  MOM_AND_BABY = 'MOM_AND_BABY',
  OFFICE_SUPPLIES = 'OFFICE_SUPPLIES',
  AGRICULTURE = 'AGRICULTURE',
  ECOMMERCE = 'ECOMMERCE',
  MANUFACTURING = 'MANUFACTURING',
  APPAREL = 'APPAREL',
  SERVICES_AND_TOURISM = 'SERVICES_AND_TOURISM',
  ENTERTAINMENT_SPORTS = 'ENTERTAINMENT_SPORTS',
  HOME_FURNISHINGS = 'HOME_FURNISHINGS',
  FASHION_PERSONAL_CARE = 'FASHION_PERSONAL_CARE',
  REAL_ESTATE = 'REAL_ESTATE',
  INFORMATION_TECHNOLOGY = 'INFORMATION_TECHNOLOGY',
  MARKETING_ADVERTISING = 'MARKETING_ADVERTISING',
  CONSTRUCTION_ARCHITECTURE = 'CONSTRUCTION_ARCHITECTURE',
  EDUCATION = 'EDUCATION',
  LAW = 'LAW',
  BEAUTY_SPA = 'BEAUTY_SPA',
  PRECIOUS_METALS_STONES = 'PRECIOUS_METALS_STONES',
  ELECTRONICS = 'ELECTRONICS',
  SHOWBIZ = 'SHOWBIZ',
  OTHER = 'OTHER',
}
export enum IndustryGroupEnum {
  BUSINESSES = 'BUSINESSES',
  GOVERNMENT_DEPARTMENTS = 'GOVERNMENT_DEPARTMENTS',
  PROVINCES_CITIES = 'PROVINCES_CITIES',
  ASSOCIATIONS = 'ASSOCIATIONS',
  EXPERTS = 'EXPERTS',
}
// other-interface
export interface AvatarType {
  fileId?: String
  originalUrl?: String
}
export interface User {
  fullName: String
  userId: ID
  avatar: AvatarType
  phone: String
}
export interface RefUserType
  extends Pick<
    B2BUserType,
    | 'id'
    | 'birthDate'
    | 'createdAt'
    | 'email'
    | 'fullName'
    | 'gender'
    | 'phone'
    | 'province'
    | 'updatedAt'
    | 'avatar'
  > {
  companyFields?: String[]
  developer?: String
  madeCommissions?: Float
  memberId?: String
  phones?: String[]
  taxId?: String
  type?: String
  statusProfile?: String
  typeSocialUser?: SocialUserTypeEnum
}

export interface MembershipRoleType {
  allowableLimit?: Int
  createdAt?: DateTime
  createdBy?: B2BUserType
  description?: String
  fees?: Boolean
  id?: ID
  renewalFee?: Float
  roleName?: String
  updatedAt?: DateTime
  updatedBy?: B2BUserType
}
export interface SocialUserOutputType {
  avatar?: FileWithFullUrls
  bank?: String
  bankAccountNumber?: String
  career?: CareerEnum
  company?: String
  createdAt?: DateTime
  createdBy?: B2BUserType
  dateOfBirth?: Date
  developer?: String
  developerMember?: RefUserType
  district?: AddressType
  email?: String
  faxNumber?: String
  fullName?: String
  gender?: String
  houseNumber?: String
  id?: String
  idNumber?: String
  imgCard?: FileWithFullUrls[]
  industryGroup?: IndustryGroupEnum
  isActive?: Boolean
  isMembership?: Boolean
  member?: any
  memberId?: String
  phone?: String
  phones?: String[]
  province?: AddressType
  representative?: any
  roleMember?: MembershipRoleType
  status?: MemberStatusEnum
  street?: AddressType
  taxId?: String
  type?: String
  typeSocialUser?: SocialUserTypeEnum
  updatedAt?: DateTime
  updatedBy?: B2BUserType
  userType?: String
  ward?: AddressType
}
type ListUserUnionPagination = PaginationOf & {items?: SocialUserOutputType[]}
export interface GetListUserUnionInfoByCompanyVariables {
  isUpdatedBy?: Boolean
  limit?: Int
  lastTime?: DateTime
  search?: String
  ids?: ObjectID[]
}
export interface GetListUserUnionInfoByCompanyData {
  getListUserUnionInfoByCompany?: ListUserUnionPagination
}

//query-data
export interface PaginationListDtoDto {
  page?: Float
  perPage?: Float
  search?: String
}
export interface CategoriesPagination {
  totalData?: Float
  totalPage?: Float
  currentPage?: Float
  perPage?: Float
  _availableSearch?: String[]
  _availableSort?: String[]
  hasNextPage?: Boolean
  links?: {
    active: Boolean
    disabled: Boolean
    label: String | undefined
    page: Number | undefined
  }[]
}
export interface ContactFormOutput {
  _id?: String
  title?: String
  slug?: String
  parent?: String
  status?: CategoryStatusEnum
  description?: String
  createdAt?: DateTime
  updatedAt?: DateTime
  deletedAt?: DateTime
  createdBy?: User
  updatedBy?: User
}
export interface CategoriesPayload extends Omit<CategoriesPagination, 'links'> {
  data?: ContactFormOutput[]
}
export interface CategoriesVariables {
  title?: String
  description?: String
  status?: CategoryStatusEnum[]
  createdBy?: String[]
  createdAt?: String
  updatedBy?: String[]
  updatedAt?: String
  isDeleted?: Boolean
  pagination?: PaginationListDtoDto
}
export interface CategoriesData {
  categories?: CategoriesPayload
}
export interface CategoryDetailVariables {
  id: String
}
export interface CategoryDetailData {
  categoryDetail?: ContactFormOutput
}
export interface DeleteCategoryVariables {
  id: String
}
export interface CreateCategoryResponse {
  msg?: String
  payload?: any
  success?: Boolean
}
export interface DeleteCategoryData {
  deleteCategory?: CreateCategoryResponse
}
export interface ReplicationCategoryVariables {
  id: String
}
export interface ReplicationCategoryData {
  replicationCategory?: CreateCategoryResponse
}
export interface RestoreCategoryVariables {
  id: String
}
export interface RestoreCategoryData {
  restoreCategory?: CreateCategoryResponse
}
export interface CategoryTreeOutput {
  _id?: String
  title?: String
  slug?: String
  status?: CategoryStatusEnum
  parent?: String
  children?: CategoryTreeOutput[]
}
export interface CategoriesTreeData {
  categoriesTree?: CategoryTreeOutput[]
}
//create category
export interface CategoryInput {
  title?: String
  slug?: String
  parent?: String
  status?: CategoryStatusEnum
  description?: String
}
export interface CreateCategoryVariables {
  data: CategoryInput
}
export interface CreateCategoryData {
  createCategory?: CreateCategoryResponse
}
export interface UpdateCategoryVariables {
  id: String
  data: CategoryInput
}
export interface UpdateCategoryData {
  updateCategory?: CreateCategoryResponse
}
//table
export interface ColumnInput {
  checked?: Boolean
  id?: String
  label?: String
}
export interface TableInput {
  tableId: String
  columns: ColumnInput[]
}
export interface UpdateTableSettingsQueryVariables {
  input: TableInput
}
export interface UpdateTableSettingsQueryData {
  updateTableSettings?: {
    success?: Boolean
    msg?: String
    payload?: String
  }
}
export interface LoadMoreState extends Omit<PaginationGraphQLState, 'perPage'> {
  until?: String | undefined
}
export interface SortState {
  availableSort?: String
  optionSort?: String
}

export interface FilterState {
  filter?: unknown
}

export interface SearchState {
  search?: String
}
export type QueryState = PaginationGraphQLState &
  SortState &
  FilterState &
  SearchState &
  LoadMoreState
export type CategoriesQueryResponse = ResponseGraphQL<CategoriesData>

//for-filter
export interface filterContactFormFormValues
  extends Omit<CategoriesVariables, 'createdAt' | 'updatedAt'> {
  createdAt?: String[]
  updatedAt?: String[]
}
export const initialFilterContactFormValues: filterContactFormFormValues = {
  title: '',
  description: '',
  status: [],
  createdAt: [],
  updatedAt: [],
  createdBy: [],
  updatedBy: [],
}
export const contactFormStatusOptions = [
  {
    value: CategoryStatusEnum.active,
    label: 'Đang hoạt động',
  },
  {
    value: CategoryStatusEnum.stop,
    label: 'Tạm ngưng',
  },
  {
    value: CategoryStatusEnum.draft,
    label: 'Bản nháp',
  },
]
