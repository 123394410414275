import {FC, useContext, useState} from 'react'
import {useQuery} from 'react-query'
import {
  QUERIES,
  WithChildren,
  createPaginationResponseContext,
  initialQueryResponse,
} from '../../../../../../../../_biha/helpers'
import {TypeEnum} from '../../../../../tag-management/core/_models'
import {getListUserUnionInfoByCompany} from '../../../../core/_requests'
import {CreatedAndUpdatedByState} from './_models'
import {getCreatedAndUpdatedBy} from './_requests'

const QueryResponseContext =
  createPaginationResponseContext<CreatedAndUpdatedByState>(initialQueryResponse)
const QueryResponseProvider: FC<WithChildren> = ({children}) => {
  const [updatedAndCreatedBy, setUpdatedAndCreatedBy] = useState<CreatedAndUpdatedByState>({
    createdBy: [],
    updatedBy: [],
  })
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const {refetch} = useQuery(
    `${QUERIES.CREATED_AND_UPDATED_BY}`,
    () => {
      return getCreatedAndUpdatedBy({type: TypeEnum.category}) // todo: đổi lại type cho đúng
    },
    {
      cacheTime: 0,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setIsLoading(true)
        const {createdBy, updatedBy} = data.data?.createdAndUpdatedBy ?? {}
        ;(async () => {
          const [createdByResponse, updatedByResponse] = await Promise.all([
            getListUserUnionInfoByCompany({
              ids: createdBy,
            }),
            getListUserUnionInfoByCompany({
              ids: updatedBy,
            }),
          ])
          const {items: createdByResponseData} =
            createdByResponse.data?.getListUserUnionInfoByCompany ?? {}
          const {items: updatedByResponseData} =
            updatedByResponse.data?.getListUserUnionInfoByCompany ?? {}
          setUpdatedAndCreatedBy({
            createdBy: createdByResponseData,
            updatedBy: updatedByResponseData,
          })
          setIsLoading(false)
        })()
      },
    }
  )
  return (
    <QueryResponseContext.Provider value={{isLoading, refetch, response: updatedAndCreatedBy}}>
      {children}
    </QueryResponseContext.Provider>
  )
}

const useQueryResponse = () => useContext(QueryResponseContext)
const useQueryResponseData = () => {
  const {response} = useQueryResponse()

  if (!response) {
    return undefined
  }
  return response || undefined
}
const useQueryResponseLoading = (): boolean => {
  const {isLoading} = useQueryResponse()
  return isLoading
}

export {QueryResponseProvider, useQueryResponse, useQueryResponseData, useQueryResponseLoading}
