import {CheckboxFilter} from '../../../../../components/checkbox-filter/CheckboxFilter'
import {TextFilter} from '../../../../../components/text-filter/TextFilter'
import {contactFormStatusOptions} from '../../../../core/_models'
import {useListUserUnionInfoByCompany} from '../../../core/list-user-union-info-by-company/ListUserUnionInfoByCompanyProvider'
import {DateFilter} from './components/DateFilter'
import {UserFilter} from './components/UserFilter'

const CommonFilter = () => {
  const userCompanyList = useListUserUnionInfoByCompany()
  const {createdBy: createdByOptions, updatedBy: updatedByOptions} = userCompanyList?.users ?? {}

  return (
    <div className='d-flex flex-wrap mx-n2 my-n1'>
      <div className='ms-2 my-1'>
        <TextFilter name='title' label='Tên' popperPlacement='bottom-start' maxLength={118} />
      </div>
      <div className='ms-2 my-1'>
        <TextFilter
          name='description'
          label='Mô tả'
          popperPlacement='bottom-start'
          maxLength={118}
        />
      </div>
      <div className='ms-2 my-1'>
        <CheckboxFilter
          name='status'
          label='Trạng thái'
          popperPlacement='bottom-start'
          options={contactFormStatusOptions}
        />
      </div>
      <div className='mx-2 my-1'>
        <DateFilter />
      </div>
      <div className='mx-2 my-1'>
        <UserFilter name='createdBy' label='Người tạo' options={createdByOptions} />
      </div>
      <div className='mx-2 my-1'>
        <UserFilter name='updatedBy' label='Người chỉnh sửa' options={updatedByOptions} />
      </div>
    </div>
  )
}

export default CommonFilter
