import {filter, find, includes, isEqual, map} from 'lodash'
import {FC} from 'react'
import {useNavigate} from 'react-router-dom'
import {Row} from 'react-table'
import {SITE_MAP} from '../../../../../../../../../../_biha/helpers'
import {useAuth} from '../../../../../../../../auth'
import {ContactFormOutput} from '../../../../../../core/_models'
import {contactFormManagementTableId} from '../../DataTable'

interface Props {
  row: Row<ContactFormOutput>
  onMouseEnterRow?: (index: number) => void
}

const TableRow: FC<Props> = ({row, onMouseEnterRow}) => {
  const {currentUser} = useAuth()
  const {tableSettings} = currentUser ?? {}
  const foundTableSettings = find(tableSettings, {tableId: contactFormManagementTableId})?.columns
  const filteredTableSettings = filter(foundTableSettings, (foundTableSetting) =>
    isEqual(foundTableSetting.checked, true)
  )
  const tableSettingIds = map(
    filteredTableSettings,
    (filteredTableSetting) => filteredTableSetting?.id
  )
  const navigate = useNavigate()

  const handleRowClick = () => {
    navigate(
      `/${SITE_MAP.CONTACT_FORM_MANAGEMENT.INDEX}/${SITE_MAP.CONTACT_FORM_MANAGEMENT.EDIT}/${row.original._id}`
    )
  }
  const handleMouseEnter = () => {
    onMouseEnterRow?.(row.index)
  }

  return (
    <tr
      {...row.getRowProps()}
      className='h-56px cursor-pointer'
      onClick={handleRowClick}
      onMouseEnter={handleMouseEnter}
    >
      {map(row.cells, (cell) => {
        return includes(tableSettingIds, cell?.column?.id) ||
          isEqual(cell?.column?.id, 'selection') ? (
          <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
        ) : null
      })}
    </tr>
  )
}

export {TableRow}
