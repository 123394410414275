/* eslint-disable react-hooks/exhaustive-deps */
import {FormikProvider, useFormik} from 'formik'
import {omit, replace} from 'lodash'
import qs from 'qs'
import {useEffect} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import {filterContactFormFormValues, initialFilterContactFormValues} from '../../../core/_models'
import CommonFilter from './common-filter/CommonFilter'

const FilterList = () => {
  const navigate = useNavigate()
  const {pathname, search} = useLocation()
  const form = useFormik<filterContactFormFormValues>({
    initialValues: initialFilterContactFormValues,
    onSubmit: (values) => {
      const searchObject = qs.parse(replace(search, '?', ''))
      navigate(
        `${pathname}?${qs.stringify(
          omit(
            {
              ...searchObject,
              ...values,
            },
            'page'
          ),
          {
            arrayFormat: 'repeat',
            encode: false,
            filter: (_, value) => value || undefined,
          }
        )}`
      )
    },
  })
  const handleResetForm = () => {
    form.resetForm()
    navigate(`${pathname}`)
  }

  useEffect(() => {
    const searchObject = qs.parse(replace(search, '?', ''))
    form.setValues(searchObject)
  }, [search])

  return (
    <div id='contact-form-management-filter-list' className='overflow-hidden'>
      <FormikProvider value={form}>
        <form
          onSubmit={form.handleSubmit}
          className='pt-6 px-8 d-flex overflow-visible d-flex justify-content-between'
        >
          <div className='me-4'>
            <CommonFilter />
          </div>
          <div>
            <button
              id='reset-btn'
              type='submit'
              className='btn btn-sm fw-bold btn-light text-gray-800 text-nowrap rounded-2 py-0 px-3 h-32px'
              onClick={handleResetForm}
            >
              <i className='ki-duotone ki-arrows-circle fs-4 me-1'>
                <span className='path1'></span>
                <span className='path2'></span>
              </i>
              <span>Đặt lại</span>
            </button>
          </div>
        </form>
      </FormikProvider>
    </div>
  )
}

export {FilterList}
