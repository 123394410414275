/* eslint-disable jsx-a11y/anchor-is-valid */
import {first, isEmpty, isNil, upperCase} from 'lodash'
import {FC} from 'react'
import {String} from '../../../../../../../../auth'
import {toAbsoluteUrl} from '../../../../../../../../../../_biha/helpers'
import {Tooltip} from '../../../../../../../components/tooltip/Tooltip'

interface Props {
  avatar?: String
  displayName?: String
}

const UserCell: FC<Props> = ({avatar, displayName}) =>
  isNil(avatar) && isNil(displayName) ? (
    <span>-</span>
  ) : (
    <div className='d-flex align-items-center'>
      <div className='symbol symbol-circle symbol-40px overflow-hidden me-4'>
        {avatar ? (
          <div className='symbol-label'>
            <img
              src={toAbsoluteUrl(isEmpty(avatar) ? '' : (avatar as string))}
              alt={isEmpty(displayName) ? 'avatar' : (displayName as string)}
              className='w-100'
            />
          </div>
        ) : (
          <div className='symbol-label fs-3 bg-gray-300 text-gray-700'>
            {upperCase(first(displayName))}
          </div>
        )}
      </div>
      <div className='d-flex flex-column'>
        {isEmpty(displayName) ? (
          <span>-</span>
        ) : (
          <Tooltip
            content={
              <div className='m-n1 p-3 d-flex flex-wrap'>
                <span className='text-nowrap text-truncate lh-lg'>{displayName}</span>
              </div>
            }
          >
            <div className='d-flex'>
              <span
                className='text-nowrap text-truncate lh-lg'
                style={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  maxWidth: '180px',
                }}
              >
                {displayName}
              </span>
            </div>
          </Tooltip>
        )}
      </div>
    </div>
  )

export {UserCell}
