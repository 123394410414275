import {Fragment} from 'react'
import {Navigate, Outlet, Route, Routes} from 'react-router-dom'
import {SITE_MAP} from '../../../../_biha/helpers'
import {PageTitle} from '../../../../_biha/layout/core'
import {ContactFormManagementListWrapper} from './contact-form-management-list/ContactFormManagementList'

const breadcrumbs = [
  {
    title: 'Quản lý phiếu liên hệ M&A từ ATM Nhà',
    path: `${SITE_MAP.CONTACT_FORM_MANAGEMENT.INDEX}`,
    isSeparator: false,
    isActive: true,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const ContactFormManagementPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path={`${SITE_MAP.CONTACT_FORM_MANAGEMENT.LIST}`}
          element={
            <Fragment>
              <PageTitle breadcrumbs={breadcrumbs}>Danh sách phiếu liên hệ</PageTitle>
              <ContactFormManagementListWrapper />
            </Fragment>
          }
        />
      </Route>
      <Route index element={<Navigate to={`${SITE_MAP.CONTACT_FORM_MANAGEMENT.LIST}`} />} />
    </Routes>
  )
}

export {ContactFormManagementPage}
