import {
  contactFormColumns,
  contactFormListObjects,
  contactFormManagementTableId,
} from '../table/data-table/DataTable'
import {EditViewTable} from './edit-view/EditViewTable'
import {ContactFormListFilter} from './filter/ContactFormListFilter'

const ContactFormListToolbar = () => {
  return (
    <div className='d-flex justify-content-end' data-kt-customer-table-toolbar='base'>
      <ContactFormListFilter />
      <EditViewTable
        tableId={contactFormManagementTableId}
        columnObjects={contactFormListObjects}
        columns={contactFormColumns}
      />
    </div>
  )
}

export {ContactFormListToolbar}
