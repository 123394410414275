/* eslint-disable react-hooks/exhaustive-deps */
import {compact, filter, find, indexOf, isEmpty, isNull, map, orderBy} from 'lodash'
import {useMemo} from 'react'
import {ColumnInstance, Row, useTable} from 'react-table'
import {KTCardBody} from '../../../../../../../_biha/helpers'
import {useAuth} from '../../../../../auth'
import {ContentLoading} from '../../../../components/content-loading/ContentLoading'
import {ContactFormOutput} from '../../../core/_models'
import {useListView} from '../../core/ListViewProvider'
import {useQueryResponseData, useQueryResponseLoading} from '../../core/QueryResponseProvider'
import {ContactFormListPagination} from '../pagination/ContactFormListPagination'
import {contactFormColumns, contactFormManagementTableId} from './data-table/DataTable'
import {TableRow} from './data-table/table-body/row/TableRow'
import {TableRowAction} from './data-table/table-body/row/TableRowAction'
import {HeaderColumn} from './data-table/table-header/HeaderColumn'

const ContactFormManagementTable = () => {
  const {selected, hoveredIndex, onHover} = useListView()
  const categories = useQueryResponseData()
  const isLoading = useQueryResponseLoading()
  const {currentUser} = useAuth()
  const {tableSettings} = currentUser ?? {}
  const data = useMemo(() => categories, [categories])
  const foundTableSettings = filter(
    find(tableSettings, {tableId: contactFormManagementTableId})?.columns,
    (item) => !isNull(item?.id)
  )
  const columns = useMemo(
    () =>
      foundTableSettings
        ? orderBy(contactFormColumns, [
            (contactFormColumn) =>
              indexOf(
                map(
                  [{checked: true, id: 'selection', label: ''}, ...foundTableSettings],
                  (foundTableSetting) => foundTableSetting.id
                ),
                contactFormColumn.id
              ),
          ])
        : contactFormColumns,
    [tableSettings]
  )
  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  })
  const orderedHeaders = find(tableSettings, {tableId: contactFormManagementTableId})?.columns
  const orderedHeaderObjects = orderedHeaders
    ? compact(
        map(
          [{id: 'selection', checked: true}, ...orderedHeaders],
          (column) => column.checked && find(headers, {id: column.id})
        )
      )
    : headers

  const handleMouseEnterRow = (index: number) => {
    onHover(index)
  }

  return (
    <KTCardBody className='px-8 pt-6 pb-8'>
      <div className='position-relative' style={{zIndex: 4}}>
        <div className='table-responsive'>
          <table
            id='kt_table_contact_form_management'
            className='table align-middle table-row-dashed fs-6 dataTable no-footer'
            {...getTableProps()}
          >
            <thead>
              <tr className='text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0 h-56px'>
                {map(
                  filter(orderedHeaderObjects, (item) => !isNull(item?.id)),
                  (column: ColumnInstance<ContactFormOutput>) => (
                    <HeaderColumn key={column.id} column={column} />
                  )
                )}
              </tr>
            </thead>
            <tbody className='text-gray-600 fw-semibold' {...getTableBodyProps()}>
              {rows?.length > 0 ? (
                map(rows, (row: Row<ContactFormOutput>, index) => {
                  prepareRow(row)
                  return (
                    <TableRow
                      row={row}
                      key={`row-${index}-${row.id}`}
                      onMouseEnterRow={handleMouseEnterRow}
                    />
                  )
                })
              ) : (
                <tr className='h-56px'>
                  <td colSpan={contactFormColumns.length}>
                    <div className='d-flex text-center w-100 align-content-center text-gray-500 fst-italic fs-6'>
                      Không tìm thấy kết quả
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {isEmpty(selected) && <TableRowAction rows={rows} index={hoveredIndex} />}
      </div>
      <ContactFormListPagination />
      {isLoading && <ContentLoading />}
    </KTCardBody>
  )
}

export {ContactFormManagementTable}
