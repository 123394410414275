/* eslint-disable jsx-a11y/anchor-is-valid */
import {find} from 'lodash'
import {FC} from 'react'
import {useIntl} from 'react-intl'
import {Boolean, Number, String} from '../../../../../../../../auth'

interface SourceItem {
  value: String | Number | Boolean
  label: String
  color?: String
  backgroundColor?: String
}
interface Props {
  translateNode: String
  value?: String | Number | Boolean
  source: SourceItem[]
  sourceName: String
}

const CustomTextOrTagCell: FC<Props> = ({translateNode, value, source, sourceName}) => {
  const intl = useIntl()

  return (
    <div className='d-flex align-items-center'>
      <div className='d-flex flex-column'>
        {(() => {
          const sourceItem = find(source, {value})
          const {backgroundColor, color, label} = sourceItem ?? {}
          return sourceItem ? (
            <div className={`d-flex align-items-center p-2 rounded bg-${backgroundColor}`}>
              <span className={`fs-7 fw-bold text-nowrap text-${color}`}>
                {translateNode
                  ? intl.formatMessage({id: `${sourceName}.${translateNode}.${label}`})
                  : label}
              </span>
            </div>
          ) : (
            '-'
          )
        })()}
      </div>
    </div>
  )
}

export {CustomTextOrTagCell}
