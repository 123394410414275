/* eslint-disable react-hooks/exhaustive-deps */
import {FormikProvider, useField, useFormik, useFormikContext} from 'formik'
import {
  compact,
  filter,
  first,
  gt,
  includes,
  isArray,
  isEmpty,
  join,
  map,
  size,
  upperCase,
} from 'lodash'
import {ChangeEvent, FC, useEffect, useState} from 'react'
import {toAbsoluteUrl} from '../../../../../../../../../_biha/helpers'
import {removeVietnameseAccents} from '../../../../../../../../../_biha/utils'
import {String} from '../../../../../../../auth'
import {SocialUserOutputType} from '../../../../../../category-mna-management/core/_models'
import {initialFilterContactFormValues} from '../../../../../core/_models'

interface Props {
  label?: String
  name: String
  options?: SocialUserOutputType[]
}
const UserFilter: FC<Props> = ({label, name, options}) => {
  const [privateSearch, setPrivateSearch] = useState<String>('')
  const [privateOptions, setPrivateOptions] = useState<SocialUserOutputType[] | undefined>()
  const outerForm = useFormikContext()
  const [field, , helpers] = useField(name)
  const {value: outerFormValue} = field
  const {setValue: setOuterFormValue} = helpers
  const innerForm = useFormik<any>({
    initialValues: initialFilterContactFormValues,
    onSubmit: (values) => {
      const {[name]: fieldName} = values
      setOuterFormValue(includes(fieldName, 'all') ? [] : fieldName)
      outerForm.submitForm()
    },
  })
  const {[name]: innerUsers} = innerForm.values
  const isSelectedAllInnerForm = includes(innerUsers, 'all')
  const searchedFieldCount = size(
    isArray(outerFormValue) ? outerFormValue : compact([outerFormValue])
  )

  const handleOnToggleModal = () => {
    innerForm.setFieldValue(name, outerFormValue)
    setPrivateSearch('')
  }
  const handleSelectAll = () => {
    innerForm.setFieldValue(name, isSelectedAllInnerForm ? [] : ['all'])
  }
  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setPrivateSearch(event.target.value)
  }
  const handleClear = () => {
    setPrivateSearch('')
    setPrivateOptions(options)
  }

  useEffect(() => {
    setPrivateOptions(options)
  }, [options])

  useEffect(() => {
    setPrivateOptions(
      filter(options, (option) =>
        includes(
          removeVietnameseAccents(option?.fullName as string),
          removeVietnameseAccents(privateSearch)
        )
      )
    )
  }, [privateSearch])

  return (
    <div className='d-inline-flex'>
      <div id={`${name}-filter`}>
        <FormikProvider value={innerForm}>
          <button
            type='button'
            className={`btn btn-sm fw-bold rounded-2 py-0 px-3 h-32px text-nowrap ${
              gt(searchedFieldCount, 0)
                ? 'bg-light-primary bg-hover-light-primary text-primary'
                : 'bg-light bg-hover-light text-gray-600'
            }`}
            data-bs-toggle='modal'
            data-bs-target={`#${name}-filter-modal`}
            onClick={handleOnToggleModal}
          >
            <span>
              {label ?? name} {gt(searchedFieldCount, 0) ? ` +${searchedFieldCount}` : ''}
            </span>
            <i className='ki-duotone ki-down fs-7 ms-1' />
          </button>
          <div className='modal fade' tabIndex={-1} id={`${name}-filter-modal`}>
            <div className='modal-dialog modal-dialog-centered modal-dialog-scrollable min-w-600px'>
              <div className='modal-content'>
                <div className='modal-header h-60px d-flex justify-content-between align-items-center p-6 position-relative border-bottom'>
                  <div className='d-flex justify-content-center align-items-center w-100'>
                    <h5 className='modal-title fs-4 ms-7'>{label ?? name}</h5>
                  </div>
                  <div
                    className='btn btn-icon btn-sm btn-active-light-primary ms-2 min-w-28px'
                    data-bs-dismiss='modal'
                    onClick={handleOnToggleModal}
                  >
                    <i className='ki-duotone ki-cross fs-2x'>
                      <span className='path1' />
                      <span className='path2' />
                    </i>
                  </div>
                </div>
                <div className='modal-body px-6 py-4'>
                  <div className='card-tittle mb-2'>
                    <div className='card-title mb-1'>
                      <div className='d-flex align-items-center rounded-2 position-relative'>
                        <i className='ki-duotone ki-magnifier fs-2 position-absolute ms-3'>
                          <span className='path1' />
                          <span className='path2' />
                        </i>
                        <input
                          autoComplete='off'
                          data-kt-table-filter={`${name}-checkbox-search`}
                          className='form-control form-control-outline w-100 ps-10 pe-8 fs-7 fw-bold h-40px py-0'
                          placeholder={'Tìm kiếm'}
                          value={privateSearch ?? ''}
                          onChange={handleSearch}
                        />
                        {privateSearch ? (
                          <button
                            className='position-absolute btn p-0 top-50 end-0 translate-middle'
                            onClick={handleClear}
                          >
                            <i className='ki-duotone ki-cross-circle fs-3'>
                              <span className='path1' />
                              <span className='path2' />
                            </i>
                          </button>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div
                    className='d-flex flex-column'
                    id={`${name}-infinite-scroll`}
                    style={{
                      maxHeight: '50vh',
                      overflowY: 'auto',
                    }}
                  >
                    <div className='d-flex flex-column'>
                      <label className='form-check form-check-custom form-check-solid mb-1 w-100 cursor-pointer px-3 py-2 rounded-2 bg-hover-light-primary user-select-none'>
                        <input
                          id={`${name}-checkbox-all`}
                          name='checkedAll'
                          className='form-check-input'
                          checked={isSelectedAllInnerForm}
                          type='checkbox'
                          value='all'
                          onChange={handleSelectAll}
                        />
                        <span className='form-check-label text-gray-600 fs-7 lh-sm fw-bold text-truncate'>
                          Chọn tất cả
                        </span>
                      </label>
                    </div>
                    {!isEmpty(privateOptions) ? (
                      <div className='d-flex flex-column'>
                        {map(privateOptions, (privateOption, privateOptionIndex) => {
                          return (
                            <div key={privateOptionIndex} className='mb-1 user-select-all'>
                              <label className='form-check form-check-custom form-check-solid w-100 cursor-pointer px-3 py-2 rounded-2 bg-hover-light-primary user-select-none'>
                                <input
                                  id={`${name}-checkbox-${privateOptionIndex}`}
                                  name={name}
                                  className='form-check-input'
                                  type='checkbox'
                                  checked={includes(innerUsers, privateOption?.id)}
                                  disabled={isSelectedAllInnerForm}
                                  value={privateOption?.id}
                                  onChange={innerForm.handleChange}
                                />
                                <div className='d-flex overflow-hidden align-items-center'>
                                  <div className='symbol symbol-circle symbol-32px overflow-hidden me-2 ms-3'>
                                    {privateOption?.avatar?.originalUrl ? (
                                      <div className='symbol-label'>
                                        <img
                                          src={toAbsoluteUrl(
                                            isEmpty(privateOption?.avatar?.originalUrl)
                                              ? ''
                                              : (privateOption?.avatar?.originalUrl as String)
                                          )}
                                          alt={
                                            isEmpty(privateOption?.fullName)
                                              ? 'avatar'
                                              : (privateOption?.fullName as String)
                                          }
                                          className='w-100'
                                        />
                                      </div>
                                    ) : (
                                      <div className='symbol-label fs-3 bg-gray-300 text-gray-700'>
                                        {upperCase(first(privateOption?.fullName))}
                                      </div>
                                    )}
                                  </div>
                                  <div className='d-flex flex-column form-check-label'>
                                    <span className='text-dark lh-sm fw-bold text-truncate w-200px'>
                                      {privateOption?.fullName}
                                    </span>
                                    <span className='mt-2'>
                                      {join(
                                        compact([privateOption.phone, privateOption.memberId]),
                                        ' - '
                                      )}
                                    </span>
                                  </div>
                                </div>
                              </label>
                            </div>
                          )
                        })}
                      </div>
                    ) : (
                      <span className='fst-italic text-gray-500 fw-bold'>Không có tùy chọn</span>
                    )}
                  </div>
                </div>
                <div className='modal-footer px-4 py-2 flex justify-content-between'>
                  <button
                    type='button'
                    className='btn btn-lg btn-light m-0 py-3 px-4 fs-7 h-40px fw-bold text-gray-800 rounded-2'
                    onClick={innerForm.handleReset}
                  >
                    <i className='ki-duotone ki-arrows-circle fs-4 me-1'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                    </i>
                    <span>Đặt lại</span>
                  </button>
                  <button
                    type='button'
                    className='btn btn-lg btn-primary rounded-2 fs-7 h-40px fw-bold m-0 py-3 px-4'
                    data-bs-dismiss='modal'
                    onClick={innerForm.submitForm}
                  >
                    Xác nhận
                  </button>
                </div>
              </div>
            </div>
          </div>
        </FormikProvider>
      </div>
    </div>
  )
}

export {UserFilter}
