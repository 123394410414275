import {KTCard} from '../../../../../_biha/helpers'
import {Content} from '../../../../../_biha/layout/components/content'
import {FilterList} from './components/filter/FilterList'
import {ContactFormManagementTable} from './components/table/ContactFormManagementTable'
import ContactFormManagementListHeader from './ContactFormManagementListHeader'
import {ListUserUnionInfoByCompanyProvider} from './core/list-user-union-info-by-company/ListUserUnionInfoByCompanyProvider'
import {ListViewProvider} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'

const ContactFormManagementList = () => {
  return (
    <Content>
      <KTCard>
        <ContactFormManagementListHeader />
        <FilterList />
        <ContactFormManagementTable />
      </KTCard>
    </Content>
  )
}

const ContactFormManagementListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <ListUserUnionInfoByCompanyProvider>
          <ContactFormManagementList />
        </ListUserUnionInfoByCompanyProvider>
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {ContactFormManagementListWrapper}
