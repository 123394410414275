import { FC, PropsWithChildren } from 'react'
import { HeaderProps } from 'react-table'
import { ContactFormOutput } from '../../../../../core/_models'
import { useListView } from '../../../../core/ListViewProvider'

interface Props {
  tableProps: PropsWithChildren<HeaderProps<ContactFormOutput>>
}

const SelectionHeaderCell: FC<Props> = ({tableProps}) => {
  const {isAllSelected, onSelectAll} = useListView()

  return (
    <th {...tableProps.column.getHeaderProps()} className='min-w-60px d-flex'>
      <div className='form-check form-check-sm form-check-custom form-check-solid me-3'>
        <input
          className='form-check-input'
          type='checkbox'
          data-kt-check={isAllSelected}
          data-kt-check-target='#kt_table_contact_form .form-check-input'
          checked={isAllSelected}
          onClick={(event) => event.stopPropagation()}
          onChange={onSelectAll}
        />
      </div>
    </th>
  )
}

export { SelectionHeaderCell }

