/* eslint-disable react/jsx-no-target-blank */
import {Fragment} from 'react'
import {SITE_MAP} from '../../../../helpers'
import {SidebarMenuItem} from './SidebarMenuItem'

const SidebarMenuMain = () => (
  <Fragment>
    <SidebarMenuItem
      to={`/${SITE_MAP.ARTICLE_MANAGEMENT.INDEX}`}
      title='Quản lý bài đăng'
      icon='/media/biha/icons/svg/article.svg'
    />
    <SidebarMenuItem
      to={`/${SITE_MAP.CATEGORY_MANAGEMENT.INDEX}`}
      title='Quản lý danh mục'
      icon='/media/biha/icons/svg/category.svg'
    />
    <SidebarMenuItem
      to={`/${SITE_MAP.TAG_MANAGEMENT.INDEX}/${SITE_MAP.TAG_MANAGEMENT.LIST}`}
      title='Quản lý thẻ'
      icon='/media/biha/icons/svg/price-tag.svg'
    />
    <SidebarMenuItem
      to={`/${SITE_MAP.BANNER_MANAGEMENT.INDEX}/${SITE_MAP.TAG_MANAGEMENT.LIST}`}
      title='Quản lý banner'
      icon='/media/biha/icons/svg/picture.svg'
    />
    <SidebarMenuItem
      to={`/${SITE_MAP.BANNER_MANAGEMENT.INDEX}/${SITE_MAP.SUBSCRIBE_NEWSLETTER_MANAGEMENT.INDEX}/${SITE_MAP.SUBSCRIBE_NEWSLETTER_MANAGEMENT.LIST}`}
      title='Quản lý đăng ký nhận tin'
      icon='/media/biha/icons/svg/questionnaire-tablet.svg'
    />
    <SidebarMenuItem
      to={`/${SITE_MAP.BANNER_MANAGEMENT.INDEX}/${SITE_MAP.FAQ_MANAGEMENT.INDEX}/${SITE_MAP.FAQ_MANAGEMENT.LIST}`}
      title='Quản lý câu hỏi thường gặp'
      icon='/media/biha/icons/svg/message-question.svg'
    />
    <SidebarMenuItem
      to={`/${SITE_MAP.BANNER_MANAGEMENT.INDEX}/${SITE_MAP.CONFIG_MANAGEMENT.INDEX}/${SITE_MAP.CONFIG_MANAGEMENT.HOME_PAGE}`}
      title='Cấu hình'
      icon='/media/biha/icons/svg/wrench.svg'
    />
    <SidebarMenuItem
      to={`/${SITE_MAP.ARTICLE_MNA_MANAGEMENT.INDEX}`}
      title='Quản lý tin M&A'
      icon='/media/biha/icons/svg/search-list.svg'
    />
    <SidebarMenuItem
      to={`/${SITE_MAP.CONTACT_FORM_MANAGEMENT.INDEX}`}
      title='Quản lý phiếu liên hệ M&A'
      icon='/media/biha/icons/svg/article.svg'
    />
    <SidebarMenuItem
      to={`/${SITE_MAP.CATEGORY_MNA_MANAGEMENT.INDEX}`}
      title='Quản lý danh mục M&A'
      icon='/media/biha/icons/svg/category.svg'
    />
  </Fragment>
)

export {SidebarMenuMain}
