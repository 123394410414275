/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {includes, isEqual, isUndefined, lte, map, min, omit, replace, toNumber} from 'lodash'
import qs from 'qs'
import {KeyboardEvent, useRef, useState} from 'react'
import {OverlayTrigger} from 'react-bootstrap'
import {useLocation, useNavigate} from 'react-router-dom'
import {SPECIAL_KEYS} from '../../../../../../../_biha/helpers'
import {Number} from '../../../../../auth'
import {useQueryResponseLoading, useQueryResponsePagination} from '../../core/QueryResponseProvider'

const ContactFormListPagination = () => {
  const ref = useRef<HTMLButtonElement>(null)
  const [paginationTerm, setPaginationTerm] = useState<string>('')
  const navigate = useNavigate()
  const {pathname, search} = useLocation()
  const searchObject = qs.parse(replace(search, '?', ''))
  const {perPage, currentPage, totalPage, totalData, links} = useQueryResponsePagination()
  const isLoading = useQueryResponseLoading()
  const startIndex = currentPage && perPage && min([(currentPage - 1) * perPage + 1, totalData])
  const endIndex = currentPage && perPage && totalData && min([currentPage * perPage, totalData])

  const updatePerPage = (newPerPage: number | undefined) => {
    if (!newPerPage || isLoading || isEqual(perPage, newPerPage)) {
      return
    }
    navigate(
      `${pathname}?${qs.stringify(
        {...omit(searchObject, 'page'), perPage: newPerPage},
        {
          arrayFormat: 'repeat',
          encode: false,
        }
      )}`
    )
    ref.current?.click()
  }
  const updatePage = (newPage: number | undefined) => {
    if (!newPage || isLoading || isEqual(currentPage, newPage)) {
      return
    }
    navigate(
      `${pathname}?${qs.stringify(
        {...searchObject, page: newPage},
        {
          arrayFormat: 'repeat',
          encode: false,
        }
      )}`
    )
  }
  const handleKeydownInput = (event: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const {key, code, metaKey} = event
    if (!metaKey && !includes(SPECIAL_KEYS, code) && key.match(/[^0-9]/)) {
      event.preventDefault()
    }
    if (isEqual(event.key, 'Enter')) {
      const newPage = toNumber(paginationTerm)
      setPaginationTerm('')
      if (newPage && totalPage && lte(newPage, totalPage)) {
        updatePage(newPage)
      }
    }
  }

  return (
    <div className='row mt-4'>
      <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'>
        <div id='kt_table_upgrade_account_record'>
          <OverlayTrigger
            rootClose
            trigger='click'
            placement='top'
            overlay={
              <div
                className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary w-72px'
                data-kt-menu='true'
              >
                <div className='py-3 px-2 d-flex flex-column'>
                  {map([10, 25, 50, 100], (record, index) => (
                    <button
                      key={index}
                      data-kt-menu-dismiss='true'
                      className='btn p-2 d-flex bg-hover-light-primary align-items-center'
                      onClick={() => updatePerPage(record)}
                    >
                      <span className='fw-medium fs-6'>{record}</span>
                    </button>
                  ))}
                </div>
              </div>
            }
          >
            <button
              ref={ref}
              disabled={isLoading}
              data-kt-menu-trigger='click'
              data-kt-menu-placement='bottom-start'
              data-kt-menu-flip='top-end'
              className='btn btn-icon justify-content-between bg-light w-72px h-32px px-3'
            >
              <span className='fw-bold fs-8 text-gray-600'>{perPage}</span>
              <span className='ki-duotone ki-down mx-1 fs-4 text-gray-600'>
                <span className='path1' />
              </span>
            </button>
          </OverlayTrigger>

          {!!startIndex && !!endIndex && !!totalData && (
            <span className='ms-6 fw-bold fs-7 text-gray-500'>{`Hiển thị ${startIndex} - ${endIndex} trên ${totalData}`}</span>
          )}
        </div>
      </div>
      <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
        <div id='kt_table_upgrade_account_paginate'>
          <ul className='pagination'>
            {map(
              links,
              (link) =>
                !isUndefined(link.label) &&
                (isEqual(link.label, 'input') ? (
                  <input
                    key={link.label}
                    type='text'
                    disabled={isLoading || link.disabled}
                    data-kt-table-filter='pagination'
                    className='page-item form-control w-108px rounded-2 fs-8 fw-bold h-32px text-center'
                    placeholder='Nhập số trang'
                    value={paginationTerm}
                    onChange={(e) => setPaginationTerm(e.target.value)}
                    onKeyDown={handleKeydownInput}
                  />
                ) : (
                  <li
                    key={link.label}
                    className={clsx('page-item', {
                      active: link.active,
                      disabled: isLoading || link.disabled,
                    })}
                  >
                    <a
                      className='page-link cursor-pointer fw-bold rounded-2'
                      onClick={() => updatePage(link.page as Number)}
                    >
                      {link.label}
                    </a>
                  </li>
                ))
            )}
          </ul>
        </div>
      </div>
    </div>
  )
}

export {ContactFormListPagination}
