import {FC, useMemo} from 'react'
import {includes} from 'lodash'
import {useListView} from '../../../../../core/ListViewProvider'
import {String} from '../../../../../../../../auth'

interface Props {
  id?: String
}

const SelectionCell: FC<Props> = ({id}) => {
  const {selected, onSelect} = useListView()
  const isSelected = useMemo(() => includes(selected, id), [id, selected])

  return (
    <div className='form-check form-check-sm form-check-custom form-check-solid'>
      <input
        className='form-check-input'
        type='checkbox'
        data-kt-check={isSelected}
        data-kt-check-target='#kt_table_customers .form-check-input'
        checked={isSelected}
        onClick={(event) => event.stopPropagation()}
        onChange={() => {
          if (id) {
            onSelect(id)
          }
        }}
      />
    </div>
  )
}

export {SelectionCell}
