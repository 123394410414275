/* eslint-disable react-hooks/exhaustive-deps */
import { FormikProvider, useFormik } from 'formik'
import { isEmpty, isEqual, omit, replace, toString } from 'lodash'
import qs from 'qs'
import { KeyboardEvent, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { InputText } from '../../../../../components/input-text/InputText'

const ContactFormListSearchTool = () => {
  const {search, pathname} = useLocation()
  const navigate = useNavigate()
  const searchObject = qs.parse(replace(search, '?', ''))
  const form = useFormik({
    initialValues: {searchString: ''},
    onSubmit: (values) => {
      navigate(
        `${pathname}?${qs.stringify(
          {
            ...omit(searchObject, 'page'),
            ...{search: values.searchString},
            ...omit(values, 'searchString'),
          },
          {arrayFormat: 'repeat', encode: false, filter: (_, value) => value || undefined}
        )}`
      )
    },
  })
  const {setValues, values} = form
  const {searchString} = values

  const handleKeydownInput = (event: KeyboardEvent<HTMLInputElement>) => {
    if (isEqual(event.key, 'Enter')) {
      event.preventDefault()
      form.handleSubmit()
    }
  }
  const handleClear = () => {
    setValues({searchString: ''})
    navigate(
      `${pathname}?${qs.stringify(
        {...omit(searchObject, 'search')},
        {arrayFormat: 'repeat', encode: false, filter: (_, value) => value || undefined}
      )}`
    )
  }

  useEffect(() => {
    setValues({
      searchString: searchString ? toString(searchString) : '',
    })
  }, [search])

  return (
    <FormikProvider value={form}>
      <div className='card-title'>
        {/* begin::Search */}
        <div className='d-flex align-items-center position-relative'>
          <i className='ki-duotone ki-magnifier fs-2 position-absolute ms-2' style={{zIndex: 1}}>
            <span className='path1'></span>
            <span className='path2'></span>
          </i>
          <InputText
            {...form.getFieldProps('searchString')}
            placeholder='Tìm kiếm'
            style={{width: 460}}
            wrapperClassName='ps-10'
            onKeyDown={handleKeydownInput}
          />
          {!isEmpty(searchString) && (
            <button
              type='button'
              className='position-absolute btn p-0 top-50 end-0 translate-middle'
              onClick={handleClear}
            >
              <i className='ki-duotone ki-cross-circle fs-3'>
                <span className='path1'></span>
                <span className='path2'></span>
              </i>
            </button>
          )}
        </div>
        {/* end::Search */}
      </div>
    </FormikProvider>
  )
}

export { ContactFormListSearchTool }

