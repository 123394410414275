import {createContext, FC, useContext} from 'react'
import {WithChildren} from '../../../../../../../_biha/helpers'
import {CreatedAndUpdatedByState} from './core/_models'
import {QueryResponseProvider, useQueryResponseData} from './core/QueryResponseProvider'

interface ListUserUnionInfoByCompanyContextProps {
  users?: CreatedAndUpdatedByState
}

const ListUserUnionInfoByCompanyContext = createContext<ListUserUnionInfoByCompanyContextProps>({})

const ListUserUnionInfoByCompanyWrapper: FC<WithChildren> = ({children}) => {
  const users = useQueryResponseData()
  return (
    <ListUserUnionInfoByCompanyContext.Provider value={{users}}>
      {children}
    </ListUserUnionInfoByCompanyContext.Provider>
  )
}

const ListUserUnionInfoByCompanyProvider: FC<WithChildren> = ({children}) => {
  return (
    <QueryResponseProvider>
      <ListUserUnionInfoByCompanyWrapper>{children}</ListUserUnionInfoByCompanyWrapper>
    </QueryResponseProvider>
  )
}
const useListUserUnionInfoByCompany = () => useContext(ListUserUnionInfoByCompanyContext)

export {ListUserUnionInfoByCompanyProvider, useListUserUnionInfoByCompany}
