/* eslint-disable react-hooks/exhaustive-deps */
import {FormikProvider, useFormik, useFormikContext} from 'formik'
import {filter, gt, isEmpty, isNull, pick, size} from 'lodash'
import moment from 'moment'
import {FormDateRangeInput} from '../../../../../../components/form-date-range-input/FormDateRangeInput'
import {
  filterContactFormFormValues,
  initialFilterContactFormValues,
} from '../../../../../core/_models'

const DateFilter = () => {
  const outerForm = useFormikContext<filterContactFormFormValues>()
  const {values: outerFormValue} = outerForm
  const innerForm = useFormik<filterContactFormFormValues>({
    initialValues: initialFilterContactFormValues,
    onSubmit: (values) => {
      const {createdAt, updatedAt} = values
      outerForm.setFieldValue('createdAt', createdAt ?? [])
      outerForm.setFieldValue('updatedAt', updatedAt ?? [])
      outerForm.submitForm()
    },
    onReset: () => {
      innerForm.setFieldValue('createdAt', undefined)
      innerForm.setFieldValue('updatedAt', undefined)
    },
  })
  const searchedFieldCount = size(
    filter(
      pick(outerFormValue, ['createdAt', 'updatedAt']),
      (field) => !isEmpty(field) && !isNull(field)
    )
  )
  const quickSelectOptions = [
    {label: '7 ngày trước', startDate: moment().add(-7, 'days'), endDate: moment()},
    {label: '30 ngày trước', startDate: moment().add(-30, 'days'), endDate: moment()},
    {label: '90 ngày trước', startDate: moment().add(-90, 'days'), endDate: moment()},
  ]

  const handleOnToggleModal = () => {
    const {createdAt, updatedAt} = outerFormValue
    innerForm.setFieldValue('createdAt', createdAt ?? [])
    innerForm.setFieldValue('updatedAt', updatedAt ?? [])
  }

  return (
    <div className='d-inline-flex'>
      <div id='contact-form-management-date-filter'>
        <FormikProvider value={innerForm}>
          <button
            type='button'
            className={`btn btn-sm fw-bold rounded-2 py-0 px-3 h-32px text-nowrap ${
              gt(searchedFieldCount, 0)
                ? 'bg-light-primary bg-hover-light-primary text-primary'
                : 'bg-light bg-hover-light text-gray-600'
            }`}
            data-bs-toggle='modal'
            data-bs-target='#contact-form-management-date-filter-modal'
            onClick={handleOnToggleModal}
          >
            <span>Thời gian {gt(searchedFieldCount, 0) ? ` +${searchedFieldCount}` : ''}</span>
            <i className='ki-duotone ki-down fs-7 ms-1'></i>
          </button>
          <div className='modal fade' tabIndex={-1} id='contact-form-management-date-filter-modal'>
            <div className='modal-dialog modal-dialog-centered modal-dialog-scrollable'>
              <div className='modal-content w-360px'>
                <div className='modal-header h-60px d-flex justify-content-end align-items-center p-4 position-relative border-0'>
                  <h5 className='modal-title position-absolute top-50 start-50 translate-middle fs-3'>
                    Thời gian
                  </h5>
                  <div
                    className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                    data-bs-dismiss='modal'
                    aria-label='Close'
                    onClick={handleOnToggleModal}
                  >
                    <i className='ki-duotone ki-cross fs-2x'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                    </i>
                  </div>
                </div>
                <div className='modal-body p-4 mb-4'>
                  <div className='d-flex flex-column'>
                    <span className='mb-3 text-gray-700 fw-bold fs-6'>Ngày tạo</span>
                    <FormDateRangeInput
                      name='createdAt'
                      placeholder='Chọn ngày tạo'
                      numberOfMonths={2}
                      maxDate={moment()}
                    />
                  </div>
                  <div className='mt-6 d-flex flex-column'>
                    <span className='mb-3 text-gray-700 fw-bold fs-6'>Ngày chỉnh sửa</span>
                    <FormDateRangeInput
                      name='updatedAt'
                      autoClose
                      quickSelectOptions={quickSelectOptions}
                      placeholder='Chọn ngày chỉnh sửa'
                      numberOfMonths={2}
                      maxDate={moment()}
                    />
                  </div>
                </div>
                <div className='modal-footer px-4 py-2 flex justify-content-between'>
                  <button
                    type='button'
                    className='btn btn-lg btn-light m-0 py-3 px-4 fs-7 h-40px fw-bold text-gray-800 rounded-2'
                    onClick={innerForm.handleReset}
                  >
                    <i className='ki-duotone ki-arrows-circle fs-4 me-1'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                    </i>
                    <span>Đặt lại</span>
                  </button>
                  <button
                    type='button'
                    className='btn btn-lg btn-primary rounded-2 fs-7 h-40px fw-bold m-0 py-3 px-4'
                    data-bs-dismiss='modal'
                    onClick={innerForm.submitForm}
                  >
                    Xác nhận
                  </button>
                </div>
              </div>
            </div>
          </div>
        </FormikProvider>
      </div>
    </div>
  )
}

export {DateFilter}
