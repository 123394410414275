import axios, { AxiosResponse } from 'axios'
import { ResponseGraphQL } from '../../../../../_biha/helpers/crud-helper/models'
import {
  CategoriesData,
  CategoriesTreeData,
  CategoriesVariables,
  CategoryDetailData,
  CategoryDetailVariables,
  CreateCategoryData,
  CreateCategoryVariables,
  DeleteCategoryData,
  DeleteCategoryVariables,
  GetListUserUnionInfoByCompanyData,
  GetListUserUnionInfoByCompanyVariables,
  ReplicationCategoryData,
  ReplicationCategoryVariables,
  RestoreCategoryData,
  RestoreCategoryVariables,
  UpdateCategoryData,
  UpdateCategoryVariables,
  UpdateTableSettingsQueryData,
  UpdateTableSettingsQueryVariables,
} from './_models'

const MAIN_API_ENDPOINT = process.env.REACT_APP_MAIN_API_ENDPOINT ?? ''
const MAIN_V1_API_ENDPOINT = process.env.REACT_APP_MAIN_V1_API_ENDPOINT ?? ''
const AUTH_API_ENDPOINT = process.env.REACT_APP_AUTH_API_ENDPOINT ?? ''
const USER_ENDPOINT = `${MAIN_API_ENDPOINT}/users/graphql`
const CMS_ENDPOINT = `${MAIN_V1_API_ENDPOINT}/cms/graphql`

const categories = async (variables: CategoriesVariables) => {
  const response: AxiosResponse<ResponseGraphQL<CategoriesData>> = await axios.post(CMS_ENDPOINT, {
    query: `
      query categories(
        $title: String
        $slug: String
        $status: [CategoryStatusEnum!]
        $createdBy: [String!]
        $createdAt: String
        $updatedBy: [String!]
        $updatedAt: String
        $isDeleted: Boolean
        $pagination: PaginationListDtoDto
      ) {
        categories(
          title: $title
          slug: $slug
          status: $status
          createdBy: $createdBy
          createdAt: $createdAt
          updatedBy: $updatedBy
          updatedAt: $updatedAt
          isDeleted: $isDeleted
          pagination: $pagination
        ) {
          totalData 
          totalPage 
          currentPage 
          perPage 
          _availableSearch
          _availableSort
          hasNextPage
          data {
            _id
            title
            slug
            parent
            status
            description
            createdAt
            updatedAt
            createdBy {
              fullName
              userId
              avatar {
                fileId
                originalUrl
              }
              phone
            }
            updatedBy {
              fullName
              userId
              avatar {
                fileId
                originalUrl
              }
              phone
            }
          }
        }
      }`,
    variables: {
      ...variables,
    },
  })
  return response.data
}
const categoryDetail = async (variables: CategoryDetailVariables) => {
  const response: AxiosResponse<ResponseGraphQL<CategoryDetailData>> = await axios.post(
    CMS_ENDPOINT,
    {
      query: `
      query categoryDetail(
        $id: String!
      ) {
        categoryDetail(
          id: $id
        ) {
           _id
           title
           slug
           parent
           status
           description
           createdAt
           updatedAt
           createdBy {
             fullName
             userId
             avatar {
               fileId
               originalUrl
             }
             phone
           }
           updatedBy {
             fullName
             userId
             avatar {
               fileId
               originalUrl
             }
             phone
           }
        }
      }`,
      variables: {
        ...variables,
      },
    }
  )
  return response.data
}
const categoriesTree = async () => {
  const response: AxiosResponse<ResponseGraphQL<CategoriesTreeData>> = await axios.post(
    CMS_ENDPOINT,
    {
      query: `
      query categoriesTree {
        categoriesTree {
          _id
          title
          slug
          status
          parent
          children
        }
      }`,
    }
  )
  return response.data
}
const getListUserUnionInfoByCompany = async (variables: GetListUserUnionInfoByCompanyVariables) => {
  const response: AxiosResponse<ResponseGraphQL<GetListUserUnionInfoByCompanyData>> =
    await axios.post(`${AUTH_API_ENDPOINT}/users/graphql`, {
      query: `
      query GetListUserUnionInfoByCompany($isUpdatedBy: Boolean, $limit: Int, $lastTime: DateTime, $search: String, $ids: [ObjectID]) {
        getListUserUnionInfoByCompany(isUpdatedBy: $isUpdatedBy, limit: $limit, lastTime: $lastTime, search: $search, ids: $ids) {
          items {
            avatar {
              originalUrl
            }
            fullName
            id
            memberId
            phone
            type
            userType
            createdAt
          }
          total
          hasNextPage
          nextPage
        }
      }
      `,
      variables: {
        ...variables,
      },
    })
  return response.data
}
function updateTableSettings(variables: UpdateTableSettingsQueryVariables) {
  return axios.post<ResponseGraphQL<UpdateTableSettingsQueryData>>(USER_ENDPOINT, {
    query: `
    mutation ($input: tableInput!) {
      updateTableSettings(input: $input) {
        msg
        payload
        success
      }
    }
      `,
    variables: {
      ...variables,
    },
  })
}
const createCategory = async (variables: CreateCategoryVariables) => {
  const response: AxiosResponse<ResponseGraphQL<CreateCategoryData>> = await axios.post(
    CMS_ENDPOINT,
    {
      query: `
      mutation createCategory($data: CategoryInput!) {
        createCategory(data: $data) {
          msg
          success
        }
      }
        `,
      variables: {
        ...variables,
      },
    }
  )
  return response.data
}
const updateCategory = async (variables: UpdateCategoryVariables) => {
  const response: AxiosResponse<ResponseGraphQL<UpdateCategoryData>> = await axios.post(
    CMS_ENDPOINT,
    {
      query: `
      mutation updateCategory($id: String!, $data: CategoryInput!) {
        updateCategory(id:$id, data: $data) {
          msg
          success
        }
      }
        `,
      variables: {
        ...variables,
      },
    }
  )
  return response.data
}
const deleteCategory = async (variables: DeleteCategoryVariables) => {
  const response: AxiosResponse<ResponseGraphQL<DeleteCategoryData>> = await axios.post(
    CMS_ENDPOINT,
    {
      query: `
      mutation deleteCategory($id: String!) {
        deleteCategory(id: $id) {
          msg
          success
        }
      }
        `,
      variables: {
        ...variables,
      },
    }
  )
  return response.data
}
const replicationCategory = async (variables: ReplicationCategoryVariables) => {
  const response: AxiosResponse<ResponseGraphQL<ReplicationCategoryData>> = await axios.post(
    CMS_ENDPOINT,
    {
      query: `
      mutation replicationCategory($id: String!) {
        replicationCategory(id: $id) {
          msg
          success
        }
      }
        `,
      variables: {
        ...variables,
      },
    }
  )
  return response.data
}
const restoreCategory = async (variables: RestoreCategoryVariables) => {
  const response: AxiosResponse<ResponseGraphQL<RestoreCategoryData>> = await axios.post(
    CMS_ENDPOINT,
    {
      query: `
      mutation restoreCategory($id: String!) {
        restoreCategory(id: $id) {
          msg
          success
        }
      }
        `,
      variables: {
        ...variables,
      },
    }
  )
  return response.data
}

export {
  categories,
  categoriesTree,
  categoryDetail,
  createCategory,
  deleteCategory,
  getListUserUnionInfoByCompany,
  replicationCategory,
  restoreCategory,
  updateCategory,
  updateTableSettings
}

