import moment from 'moment'
import { Column } from 'react-table'
import { CategoryStatusEnum, ContactFormOutput } from '../../../../core/_models'
import { CustomTextOrTagCell } from './table-body/column/CustomTextOrTagCell'
import { SelectionCell } from './table-body/column/SelectionCell'
import { TextCell } from './table-body/column/TextCell'
import { UserCell } from './table-body/column/UserCell'
import { BaseHeader } from './table-header/BaseHeader'
import { SelectionHeaderCell } from './table-header/SelectionHeaderCell'

export const contactFormManagementTableId = 'contact-form-management-table'

export const contactFormListObjects = [
  {
    label: '',
    id: 'selection',
  },
  {
    label: 'STT',
    id: 'number',
  },
  {
    label: 'Tên',
    id: 'title',
  },
  {
    label: 'Mô tả',
    id: 'description',
  },
  {
    label: 'Đường dẫn',
    id: 'slug',
  },
  {
    label: 'Trạng thái',
    id: 'status',
  },
  {
    label: 'Người tạo',
    id: 'createdBy',
  },
  {
    label: 'Ngày tạo',
    id: 'createdAt',
  },
  {
    label: 'Người chỉnh sửa',
    id: 'updatedBy',
  },
]
const contactFormColumns: ReadonlyArray<Column<ContactFormOutput>> = [
  {
    Header: (props) => <SelectionHeaderCell tableProps={props} />,
    id: 'selection',
    Cell: ({...props}) => <SelectionCell id={props.data[props.row.index]?._id} />,
  },
  {
    Header: (props) => <BaseHeader tableProps={props} title='STT' className='min-w-60px' />,
    id: 'number',
    Cell: ({...props}) => <TextCell value={props.row.index + 1} />,
  },
  {
    Header: (props) => <BaseHeader tableProps={props} title='TÊN' className='min-w-196px' />,
    id: 'title',
    Cell: ({...props}) => (
      <TextCell value={props.data[props.row.index]?.title} className='mw-196px' />
    ),
  },
  {
    Header: (props) => <BaseHeader tableProps={props} title='MÔ TẢ' className='min-w-216px' />,
    id: 'description',
    Cell: ({...props}) => (
      <TextCell value={props.data[props.row.index]?.description} className='mw-216px' />
    ),
  },
  {
    Header: (props) => <BaseHeader tableProps={props} title='ĐƯỜNG DẪN' className='min-w-196px' />,
    id: 'slug',
    Cell: ({...props}) => (
      <TextCell value={props.data[props.row.index]?.slug} className='mw-196px' />
    ),
  },
  {
    Header: (props) => <BaseHeader tableProps={props} title='TRẠNG THÁI' className='min-w-180px' />,
    id: 'status',
    Cell: ({...props}) => (
      <CustomTextOrTagCell
        value={props.data[props.row.index]?.status}
        translateNode='STATUS'
        sourceName='CONTACT_FORM'
        source={[
          {
            value: CategoryStatusEnum.active,
            label: CategoryStatusEnum.active,
            color: 'success',
            backgroundColor: 'light-success',
          },
          {
            value: CategoryStatusEnum.draft,
            label: CategoryStatusEnum.draft,
            color: 'gray-600',
            backgroundColor: 'gray-200',
          },
          {
            value: CategoryStatusEnum.stop,
            label: CategoryStatusEnum.stop,
            color: 'primary',
            backgroundColor: 'light-primary',
          },
        ]}
      />
    ),
  },
  {
    Header: (props) => <BaseHeader tableProps={props} title='NGƯỜI TẠO' className='min-w-200px' />,
    id: 'createdBy',
    Cell: ({...props}) => (
      <UserCell
        avatar={props.data[props.row.index]?.createdBy?.avatar?.originalUrl}
        displayName={props.data[props.row.index]?.createdBy?.fullName}
      />
    ),
  },
  {
    Header: (props) => <BaseHeader tableProps={props} title='Ngày tạo' className='min-w-152px' />,
    id: 'createdAt',
    Cell: ({...props}) => (
      <TextCell
        value={
          props.data[props.row.index]?.createdBy && props.data[props.row.index]?.createdAt
            ? moment(props.data[props.row.index]?.createdAt).format('HH:mm:ss, DD/MM/YYYY')
            : undefined
        }
        className='mw-152px'
      />
    ),
  },
  {
    Header: (props) => (
      <BaseHeader tableProps={props} title='NGƯỜI CHỈNH SỬA' className='min-w-200px' />
    ),
    id: 'updatedBy',
    Cell: ({...props}) => (
      <UserCell
        avatar={props.data[props.row.index]?.updatedBy?.avatar?.originalUrl}
        displayName={props.data[props.row.index]?.updatedBy?.fullName}
      />
    ),
  },
]

export { contactFormColumns }

